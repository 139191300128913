import { Box, FormControlLabel, Checkbox, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  blocked?: boolean
}

export const VisibilityEditor: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const methods = useFormContext()

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const onTooltipOpen = () => {
    if (props.blocked) {
      setTooltipOpen(true)
    }
  }

  const onTooltipClose = () => {
    setTooltipOpen(false)
  }

  return <Box>
    <Tooltip title={t('edit.content.toolTips.cannotHideContent')} open={tooltipOpen} onOpen={onTooltipOpen} onClose={onTooltipClose}>
      <span>
        <Controller
          name="hidden"
          control={methods.control}
          render={({ field: { onChange, value } }) => <FormControlLabel
            control={<Checkbox sx={{ py: 0 }}
              checked={value}
              disabled={props.blocked}
              onChange={e => onChange(e.currentTarget.checked)}
            />}
            label={t('common.hide')}
          />}
        />
      </span>
    </Tooltip>
  </Box>
}
