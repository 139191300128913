import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { convertToTextObject } from '@utils/texts'
import { UpdateContentInput } from '@typings/graphql'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Chip, Stack, TextField, TextareaAutosize } from '@mui/material'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { deleteTexts } from '../mutation-helper/text'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { textConfigSchema } from '../config-schemas/default/text'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateTextContentFormInput = {
  text: string,
  accessibilityText: string,
  blockedById: string | null,
  hidden: boolean,
  style: string,
  config: string,
}

const textKeys = ['text', 'accessibilityText']

export const TextContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const getDefaultValues = () => {
    const values: Record<string, string> = {}

    textKeys.forEach((key) => {
      values[key] = content.texts[key]
    })

    return {
      ...values,
      blockedById: content.blockedById || null,
      hidden: content.hidden ?? false,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateTextContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = async (submittedData: UpdateTextContentFormInput) => {
    const translatableTexts = textKeys
      .filter((key) => !!submittedData[key as 'text' | 'accessibilityText'])
      .map((key) => convertToTextObject(key, submittedData[key as 'text' | 'accessibilityText']))

    const deletedTextsVariables = textKeys
      .filter((key) => !submittedData[key as 'text' | 'accessibilityText'] && content.texts[key])
      .map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

    const data: UpdateContentInput = {
      nuggetId,
      texts: translatableTexts,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      hidden: submittedData.hidden,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style)
    }
    setLoading(true)

    if (deletedTextsVariables.length > 0) {
      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setUploadError(e)
      }
    }

    try {
      await updateContent(content.id, data)

      methods.reset()
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setUploadError(null)
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <TextField label="text" {...methods.register('text')} sx={{ width: '100%' }} />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="block">
            <Chip label="accessibilityText" size="small" sx={{ marginBottom: '8px' }} />
          </Box>
          <TextareaAutosize
            minRows={3}
            {...methods.register('accessibilityText')}
            style={{ width: '100%' }}
          />
        </Box>
        <ConfigAndStyleEditor content={content} schema={textConfigSchema} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>

      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
