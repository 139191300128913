import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Box, Button, Chip, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { TranslatableTextInput, UpdateContentInput, UploadFileMutationVariables } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import cuid from 'cuid'
import ReactAudioPlayer from 'react-audio-player'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { hi } from 'date-fns/locale'

import { updateContent } from '../mutation-helper/content'
import { ContentFile, deleteContentFiles, replaceContentFiles, uploadContentFiles, uploadFile } from '../mutation-helper/file'
import { deleteTexts } from '../mutation-helper/text'
import { InteractionCardFormInputs, InteractionCardEditor, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { audioCarouselConfigSchema } from '../config-schemas/default/audioCarousel'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

const textKeys = ['exerciseTitle', 'exerciseDescription', 'quickTipTitle', 'quickTipText', 'closeButtonText'] as const

type UpdateAudioCarouselContentFormInput = InteractionCardFormInputs & {
  texts: Record<typeof textKeys[number], string>,
  quickTipMaterialIcon: string | null,
  files: {
    file: ContentFile | File | null,
    replaceId: string | null,
    key: string,
    title: string,
    text: string
  }[],
  hidden: boolean,
  style: string,
  config: string
}

type FileIdAndKey = {
  id: string,
  key: string
}

export const AudioCarouselContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedFileIdsAndKeys, setDeletedFileIdsAndKeys] = useState<FileIdAndKey[]>([])

  const defaultValues = useMemo(() => {
    return {
      texts: textKeys.reduce((acc, curr) => {
        acc[curr] = content.texts[curr] || ''

        return acc
      }, {} as Record<typeof textKeys[number], string>),
      interactionCardTexts: interactionCardTextKeys.map((key) => ({
        key,
        value: content.texts[key] || ''
      })),
      quickTipMaterialIcon: content.config.quickTipMaterialIcon ?? '',
      files: [...content.files].map((file) => ({
        file,
        replaceId: null,
        key: file.key,
        title: content.texts[`${file.key}_title`] || '',
        text: content.texts[`${file.key}_text`] || ''
      })),
      hidden: content.hidden ?? false,
      blockedById: content.blockedById || null,
      config: JSON.stringify(content.config, null, '\t') || '',
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }, [content])

  const methods = useForm<UpdateAudioCarouselContentFormInput>({
    defaultValues,
    mode: 'onChange'
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: 'files'
  })

  const replaceFile = (newFile: File, oldFile: ContentFile, index: number) => {
    methods.setValue(`files.${index}.file`, newFile, { shouldDirty: true })

    if (oldFile) methods.setValue(`files.${index}.replaceId`, oldFile.id)
  }

  const deleteFile = (index: number, fileId: string, key: string) => {
    remove(index)

    if (fileId) {
      setDeletedFileIdsAndKeys((prev) => [...prev, {
        id: fileId,
        key
      }])
    }
  }

  const addFileItem = () => {
    append({
      file: null,
      replaceId: null,
      key: cuid(),
      title: '',
      text: ''
    })
  }

  const textEditItems = useMemo(() => (
    textKeys.map((key) => {
      return <Controller
        key={key}
        name={`texts.${key}`}
        control={methods.control}
        render={({ field: { onChange, value } }) => {
          return <TextField
            sx={{ marginBottom: '40px' }}
            label={t(`edit.content.${key}`)}
            value={value}
            onChange={onChange}
          />
        }}
      />
    })
  ), [])

  const fileEditItems = useMemo(() => (
    fields.map((field, index) => {
      const { file, id } = field

      return (
        <Box
          key={id}
          minHeight="250px"
          display="flex"
          alignItems="flex-start"
          padding="16px"
          border="1px solid #CFD7DD"
          borderTop={index === 0 ? undefined : 'none'}
        >
          <Box flexDirection="column">
            <FileDragDrop
              accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
              height="100%"
              width="100%"
              preview
              onFileChanged={(newFile) => replaceFile(newFile, file as ContentFile, index)}
            >
              <Typography>{ !content?.files[index] ? t('edit.poi.dragAudio') : content.files[index].fileName}</Typography>
            </FileDragDrop>
            {(methods.getValues(`files.${index}.file`) || !!content.files.length) &&
            <ReactAudioPlayer
              src={methods.getValues(`files.${index}.file`) && !(methods.getValues(`files.${index}.file`) as any).url
                ? URL.createObjectURL(methods.getValues(`files.${index}.file`) as File)
                : content.files[0].url}
              controls
              style={{ marginTop: '12px' }}
            />
          }
          </Box>
          <Box flexDirection="column">
            <Controller
              control={methods.control}
              name={`files.${index}.title`}
              rules={{ validate: () => methods.getValues(`files.${index}.file`) !== null }}
              render={({ field: { onChange, value }, fieldState: { error: e } }) =>
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!e}
                  sx={{ marginBottom: '32px', marginLeft: '18px', width: '500px' }}
                  helperText={!!e && t('edit.content.fileRequired')}
                  label={t('common.title')}
                />}
            />
            <MarkdownEditor
              name={`files.${index}.text`}
              height={150}
              style={{ marginBottom: '32px', marginLeft: '18px', width: '500px' }}
            />
          </Box>
          <Box padding="10px" height="56px">
            <IconButton onClick={() => deleteFile(index, (file as any)?.id, (file as any)?.key)}>
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
        </Box>
      )
    })
  ), [fields])

  const onSubmit = async (submittedData: UpdateAudioCarouselContentFormInput) => {
    setLoading(true)

    const carouselFiles = submittedData.files.filter((file) => file.file !== null)

    const textUpdateVariables: TranslatableTextInput[] = []

    carouselFiles.forEach((fileItem) => {
      if (fileItem.title && fileItem.title !== content.texts[`${fileItem.key}_title`]) {
        textUpdateVariables.push(convertToTextObject(`${fileItem.key}_title`, fileItem.title))
      }

      if (fileItem.text && fileItem.text !== content.texts[`${fileItem.key}_text`]) {
        textUpdateVariables.push(convertToTextObject(`${fileItem.key}_text`, fileItem.text))
      }
    })

    textKeys.forEach((key) => {
      if (submittedData.texts[key] && submittedData.texts[key] !== content.texts[key]) {
        textUpdateVariables.push(convertToTextObject(key, submittedData.texts[key]))
      }
    })

    submittedData.interactionCardTexts.forEach((text) => {
      if (text.value && text.value !== content.texts[text.key]) {
        textUpdateVariables.push(convertToTextObject(text.key, text.value))
      }
    })

    if (submittedData.descriptionImage?.file && !(submittedData.descriptionImage.file as any).id) {
      try {
        await uploadFile({
          file: submittedData.descriptionImage.file,
          data: {
            key: 'description',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    const newFilesUploadVariables = carouselFiles.reduce((acc, curr, index) => {
      if (!(curr.file as any)?.id && !curr.replaceId) {
        textUpdateVariables.push(convertToTextObject(curr.key, curr.text))

        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            order: index,
            model: 'Content',
            modelId: content.id
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        setError(e)
      }
    }

    const replaceFileVariables = carouselFiles.reduce((acc, curr, index) => {
      if (curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            replaceId: curr.replaceId,
            order: index,
            model: 'Content',
            modelId: content.id
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (replaceFileVariables.length) {
      try {
        await replaceContentFiles(replaceFileVariables)
      } catch (e) {
        setError(e)
      }
    }

    if (deletedFileIdsAndKeys.length) {
      const deletedFileIds = deletedFileIdsAndKeys.map((item) => ({ id: item.id }))
      const deletedTextsVariables = deletedFileIdsAndKeys.map((item) => ({
        key: item.key,
        model: 'Content',
        modelId: content.id
      }))

      textKeys.forEach((key) => {
        if (!submittedData.texts[key] && content.texts[key]) {
          deletedTextsVariables.push({
            key,
            model: 'Content',
            modelId: content.id
          })
        }
      })

      try {
        await deleteContentFiles(deletedFileIds)
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      config: {
        ...JSON.parse(submittedData.config),
        quickTipMaterialIcon: submittedData.quickTipMaterialIcon
      },
      hidden: submittedData.hidden,
      style: JSON.parse(submittedData.style),
      texts: textUpdateVariables
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <InteractionCardEditor content={content} />
        <Divider />
        <Box display="flex" flexDirection="column" pt="40px">
          {textEditItems}
          <TextField
            key="quick-tip-icon"
            sx={{ marginBottom: '12px', widht: '500px' }}
            label={t('common.materialIcon')}
            {...methods.register('quickTipMaterialIcon')}
          />
        </Box>
        <Box>
          <Chip label="config" size="small" sx={{ marginBottom: '12px' }} />
          {fileEditItems}
        </Box>
        <Box width="600xpx">
          <Button variant="outlined" onClick={addFileItem}>
            <Typography sx={{ marginRight: 1 }}>{t('edit.content.addFile')}</Typography>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <Box marginY="64px">
          <ConfigAndStyleEditor content={content} schema={audioCarouselConfigSchema} />
        </Box>
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
