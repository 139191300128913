import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { UpdateContentInput, UploadFileMutationVariables } from '@typings/graphql'
import { Box, Button, Chip, Divider, IconButton, Stack, TextareaAutosize, TextField, Typography } from '@mui/material'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { VideoPlayer } from '@features/cms/components/VideoPlayer'
import { useTranslation } from 'react-i18next'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { EditableFile, InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { VoiceoverEditor } from '../components/VoiceoverEditor'
import { ContentFile, deleteContentFiles, replaceContentFiles, uploadContentFiles, uploadFile } from '../mutation-helper/file'
import { updateContent } from '../mutation-helper/content'
import { deleteTexts } from '../mutation-helper/text'
import { MediaInfos } from '../components/MediaInfos'
import { annotatedVideoConfigSchema } from '../config-schemas/default/annotatedVideo'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type AnnotatedVideoContentFormInput = InteractionCardFormInputs & {
  voiceover: {
    key: string,
    editableFile: EditableFile,
    text: {
      key: string,
      value: string
    }
  }[],
  video: EditableFile,
  additionalTexts: {
    key: 'accessibilityTextVideo' | 'accessibilityHint',
    value: string
  }[],
  hidden: boolean,
  blockedById: string | null,
  style: string,
  config: string
}

type FileIdAndKey = {
  id: string,
  key: string
}

const additionalTextKeys = ['accessibilityTextVideo', 'accessibilityHint'] as const

export const AnnotatedVideoContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedVoiceoverIdsAndKeys, setDeletedVoiceoverIdsAndKeys] = useState<FileIdAndKey[]>([])

  const voiceover = useMemo(() => {
    const voiceoverFiles = content.files.filter((file) => file.key.includes('voiceover'))
    const voiceoverTexts: { key: string, value: string }[] = Object.keys(content.texts)
      .filter((key) => key.includes('text_'))
      .map((key) => ({ key, value: content.texts[key] }))

    const voiceoverItems = voiceoverFiles.length > voiceoverTexts.length ? voiceoverFiles : voiceoverTexts

    if (voiceoverItems.length) {
      return voiceoverItems.map((item) => {
        const keyNumber = item.key.replace(/^\D+/g, '')

        return {
          key: keyNumber,
          editableFile: {
            key: `voiceover_${keyNumber}`,
            file: content.files.find((file) => file.key === `voiceover_${keyNumber}`) || null,
            replaceId: null
          },
          text: {
            key: `text_${keyNumber}`,
            value: content.texts[`text_${keyNumber}`] || ''
          }
        }
      })
    } else {
      return [{
        key: '1',
        editableFile: {
          key: 'voiceover_1',
          file: null,
          replaceId: null
        },
        text: {
          key: 'text_1',
          value: ''
        }
      }]
    }
  }, [content.files, content.texts])

  const additionalTexts = useMemo(() => {
    return additionalTextKeys
      .map((key) => ({
        key,
        value: content.texts[key] || ''
      }))
  }, [content.texts])

  const getDefaultValues = (): AnnotatedVideoContentFormInput => ({
    interactionCardTexts: interactionCardTextKeys.map((key) => ({
      key,
      value: content.texts[key] || ''
    })),
    descriptionImage: {
      key: 'description',
      file: content.files.find((file) => file.key === 'description') || null,
      replaceId: null
    },
    video: {
      key: 'video',
      file: content.files.find((file) => file.key === 'video') || null,
      replaceId: null
    },
    voiceover,
    additionalTexts,
    hidden: content.hidden || false,
    blockedById: content.blockedById || null,
    style: JSON.stringify(content.style, null, '\t') || '',
    config: JSON.stringify(content.config, null, '\t') || ''
  })

  const methods = useForm<AnnotatedVideoContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields: voiceoverFields, append: appendVoiceover, remove: removeVoiceover } = useFieldArray({
    control: methods.control,
    name: 'voiceover'
  })

  const { fields: additionalTextFields } = useFieldArray({
    control: methods.control,
    name: 'additionalTexts'
  })

  const textInputs = useMemo(() => (
    additionalTextFields.map((field, index) => {
      const { key } = field

      return (<Box key={key} display="flex" flexDirection="column" mt={4}>
        <Box display="block">
          <Chip label={key} size="small" sx={{ marginBottom: '8px' }} />
        </Box>
        <TextareaAutosize
          minRows={3}
          {...methods.register(`additionalTexts.${index}.value`)}
          style={{ width: 600, marginBottom: '60px' }}
        />
      </Box>)
    })
  ), [additionalTextFields])

  const deleteVoiceover = (index: number, fileId: string, key: string) => {
    removeVoiceover(index)

    if (fileId) {
      setDeletedVoiceoverIdsAndKeys((prev) => [...prev, { key, id: fileId }])
    }
  }

  const addVoiceover = () => {
    const lastKey = voiceoverFields.length > 1 ? parseInt(voiceoverFields[voiceoverFields.length - 1].key.replace(/^\D+/g, '')) : 1

    appendVoiceover({
      key: `${lastKey + 1}`,
      editableFile: {
        key: `voiceover_${lastKey + 1}`,
        file: null,
        replaceId: null
      },
      text: {
        key: `text_${lastKey + 1}`,
        value: ''
      }
    })
  }

  const voiceoverInputs = useMemo(() => {
    return voiceoverFields.map((item, index) => {
      return (<React.Fragment key={item.key}>
        <Box display="flex" alignItems="flex-start" height="auto">
          <VoiceoverEditor
            content={content}
            index={index}
            fileKey={item.editableFile.key}
            textKey={item.text.key}
            label={`Stop ${index + 1}`}
            formMethods={methods}
          />
          <Box padding="10px" marginLeft="16px" marginTop="16px" height="56px">
            <IconButton onClick={() => deleteVoiceover(index, (item.editableFile.file as any)?.id, item.key)}>
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
          {(index + 1 !== voiceoverFields.length) && <Divider />}
        </Box>
        <Divider />
      </React.Fragment>
      )
    })
  }, [voiceoverFields, content])

  const addVideo = (file: File) => {
    methods.setValue('video.file', file, { shouldDirty: true })
  }

  const video = useWatch({
    control: methods.control,
    name: 'video.file'
  })

  const onSubmit = async (submittedData: AnnotatedVideoContentFormInput) => {
    setLoading(true)

    if (submittedData.descriptionImage.file && !(submittedData.descriptionImage.file as any).id) {
      try {
        await uploadFile({
          file: submittedData.descriptionImage.file,
          data: {
            key: 'description',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    if (submittedData.video.file && !(submittedData.video.file as any).id) {
      try {
        await uploadFile({
          file: submittedData.video.file,
          data: {
            key: 'video',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    const newFilesUploadVariables = submittedData.voiceover.reduce((acc, curr, index) => {
      const replaceId = content.files.find((f) => f.key === curr.editableFile.key)?.id

      if (curr.editableFile.file && !(curr.editableFile.file as any).id && !replaceId) {
        acc.push({
          file: curr.editableFile.file,
          data: {
            key: `voiceover_${curr.key}`,
            order: index + 2,
            model: 'Content',
            modelId: content.id
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        setError(e)
      }
    }

    const replaceFileVariables = submittedData.voiceover.reduce((acc, curr, index) => {
      const replaceId = content.files.find((f) => f.key === curr.editableFile.key)?.id

      if (replaceId && curr.editableFile.file && !(curr.editableFile.file as any)?.id) {
        acc.push({
          file: curr.editableFile.file,
          data: {
            key: `voiceover_${curr.key}`,
            replaceId,
            order: index,
            model: 'Content',
            modelId: content.id
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (replaceFileVariables.length) {
      try {
        await replaceContentFiles(replaceFileVariables)
      } catch (e) {
        setError(e)
      }
    }

    if (deletedVoiceoverIdsAndKeys.length) {
      const deletedFileIds = deletedVoiceoverIdsAndKeys.map((item) => ({ id: item.id }))
      const deletedTextsVariables = deletedVoiceoverIdsAndKeys.map((item) => ({
        key: `text_${item.key}`,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteContentFiles(deletedFileIds)
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const updateVoiceoverTextVariables = submittedData.voiceover.map((item) => ({
      key: item.text.key,
      value: item.text.value
    }))

    const updateTextVariables = submittedData.interactionCardTexts.concat(updateVoiceoverTextVariables, submittedData.additionalTexts)
    const deletedTextKeys = updateTextVariables
      .filter((text) => !text.value)
      .map((deletedText) => deletedText.key)

    const deletedTextsVariables = deletedTextKeys.map((key) => ({
      key,
      model: 'Content',
      modelId: content.id
    }))

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      hidden: submittedData.hidden,
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: updateTextVariables
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setError(e)
    }

    await deleteTexts(deletedTextsVariables)

    setLoading(false)
    setShowSnackbar(true)
    refetch && await refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <TextField
            sx={{ marginBottom: '40px', width: '500px' }}
            label={t('edit.content.blockedBy')}
            {...methods.register('blockedById')}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <InteractionCardEditor content={content} />
        <Divider />
        <Box marginY="64px">
          <FileDragDrop
            accept={{ 'video/*': ['.mp4'] }}
            preview
            style={{ marginBottom: '32px' }}
            onFileChanged={(file) => addVideo(file)}
          >
            {!content.files.find((file) => file.key === 'video')
              ? <Typography>{t('edit.poi.dragVideo')}</Typography>
              : <Stack>
                <Typography>{content.files.find((file) => file.key === 'video')!.fileName}</Typography>
                <MediaInfos file={content.files.find((file) => file.key === 'video')}></MediaInfos>
              </Stack>
              }
          </FileDragDrop>
          {video && <VideoPlayer
            height="400px"
            url={(video as any).id
              ? (video as ContentFile).url
              : URL.createObjectURL(video as File)}
            controls
          />
        }
        </Box>
        <Divider />
        <Box marginY="64px">
          <ConfigAndStyleEditor content={content} schema={annotatedVideoConfigSchema} />
        </Box>
        <Divider />
        {voiceoverInputs}
        <Box my={3}>
          <Button variant="outlined" onClick={addVoiceover}>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <Divider />
        {textInputs}
        <Divider />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
