import { Box, Button, List, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material'
import { TourPoiQuery, useCloneContentMutation, useDeleteContentMutation, useReorderContentMutation, useTourPoiQuery } from '@typings/graphql'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import ListIcon from '@mui/icons-material/List'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid3x3Icon from '@mui/icons-material/Grid3x3'
import CopyIcon from '@mui/icons-material/ContentCopyOutlined'
import { ConfirmDialog } from '@features/cms/components/dialogs/ConfirmDialog'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'

import { EditContentPanel } from './EditContentPanel'
import { CreateContentDialog } from './dialogs/CreateContentDialog'

type ItemProps = {
  content: TourPoiQuery['tourPoi']['sections'][0]['nuggets'][0]['content'][number],
  selected: boolean,
  onDelete: () => void,
  onClick: () => void
  onDuplicate: () => void
}

const ContentNuggetListItem: React.FC<ItemProps> = ({
  content,
  selected,
  onDelete,
  onClick,
  onDuplicate
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id: content.id })

  const [duplicateContentMutation] = useCloneContentMutation()

  const { t } = useTranslation()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const duplicateContent = async (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    await duplicateContentMutation({
      variables: {
        id: content.id
      }
    })

    onDuplicate()
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <ListItem
        key={content.id}
        sx={{
          opacity: content.hidden ? 0.3 : 1,
          '& .MuiListItemSecondaryAction-root': {
            right: 0,
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
            px: 0.5,
            backgroundColor: '#ddd9',
            borderRadius: 2,
            mr: 0.5
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0
          },
          color: selected ? 'primary.main' : 'inherit',
          ':hover': {
            '& .MuiListItemSecondaryAction-root': {
              opacity: 1
            }
          }
        }}
        secondaryAction={
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Tooltip title={t('common.copyId')}>
              <Button variant="text"
                size="small"
                sx={{ p: 1, minWidth: 0, color: '#666' }}
                onClick={() => navigator.clipboard.writeText(content.id)}
              >
                <Grid3x3Icon fontSize="small" sx={{ fontSize: 16 }} />
              </Button>
            </Tooltip>
            <Tooltip title={t('common.duplicate')}>
              <Button variant="text"
                size="small"
                sx={{ p: 1, minWidth: 0, color: '#666' }}
                onClick={duplicateContent}
              >
                <CopyIcon fontSize="small" sx={{ fontSize: 16 }} />
              </Button>
            </Tooltip>
            <Tooltip title={t('common.delete')}>
              <Button variant="text"
                size="small"
                sx={{ p: 1, minWidth: 0, color: '#666' }}
                onClick={onDelete}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title={t('common.move')} placement="right">
              <ListIcon ref={setActivatorNodeRef as any} {...listeners} sx={{ cursor: 'move', color: '#666' }} />
            </Tooltip>
          </Box>
      }
        disablePadding
      >
        <ListItemButton onClick={onClick}>
          <ListItemText
            sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', pr: 4 }}
            id={content.id}
            primary={`${content.order}. ${content.type}`}
          />
        </ListItemButton>
      </ListItem>
    </div>
  )
}

const PoiNuggetContent: React.FC = () => {
  const { t } = useTranslation()
  const { modeId, poiId, sectionId, nuggetId, contentId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [selectedContent, setSelectedContent] = React.useState(contentId)
  const [showDialog, setShowDialog] = React.useState(false)
  const [contentIdToDelete, setContentIdToDelete] = useState('')
  const [deleteContent] = useDeleteContentMutation()
  const [reorderContent] = useReorderContentMutation()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const hasUnsavedChanges = useRef(false)

  const { data, refetch } = useTourPoiQuery({ variables: { id: poiId as string }, fetchPolicy: 'network-only' })
  const poi = useMemo(() => data?.tourPoi, [data])

  const nugget = useMemo(() =>
    poi?.sections.find((s) => s.id === sectionId)?.nuggets.find((n) => n.id === nuggetId),
  [poi, sectionId, nuggetId]
  )

  const contents = useMemo(() => {
    if (!nugget) {
      return []
    }

    const arr = [...nugget.content]
    arr.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))

    if (!selectedContent) {
      setSelectedContent(arr[0]?.id)
    }

    return arr
  }, [nugget])

  const selectContent = (id: string) => {
    if (hasUnsavedChanges.current) {
      if (window.confirm('Möchtest du wirklich die Seite wechseln? Deine Änderungen gehen dadurch verloren.')) {
        setSelectedContent(id)
      }
    } else {
      setSelectedContent(id)
    }
  }

  const onConfirmDelete = useCallback(async () => {
    try {
      await deleteContent({
        variables: {
          id: contentIdToDelete
        }
      })

      setSelectedContent(undefined)
      setContentIdToDelete('')
      refetch?.()
    } catch (e) {
      console.log(e)
    }
  }, [contentIdToDelete])

  const listItems = useMemo(() => contents.map((content) => {
    return <ContentNuggetListItem
      key={content.id}
      content={content}
      selected={content.id === selectedContent}
      onClick={() => selectContent(content.id)}
      onDuplicate={() => refetch()}
      onDelete={() => setContentIdToDelete(content.id)}
    />
  }), [contents, selectedContent])

  const contentPanel = useMemo(() => {
    if (!nugget) {
      return null
    }

    const blocksContent = !!contents.find((c) => c.blockedById === selectedContent) ||
      !!contents.find((c) => c.id === selectedContent)?.blockedById

    return <EditContentPanel
      nuggetId={nugget.id}
      contentId={selectedContent}
      blocksContent={blocksContent}
      // content={contents.find((c) => c.id === selectedContent)}
      modeId={modeId as string}
      refetch={() => refetch()}
      onEdited={(value) => { hasUnsavedChanges.current = value }}
    />
  }, [selectedContent, contents])

  useEffect(() => {
    if (!selectedContent) {
      return
    }
    hasUnsavedChanges.current = false

    const parts = pathname.split('/')
    if (!contentId) {
      parts.push('')
    }
    parts[parts.length - 1] = selectedContent

    navigate(parts.join('/').replace('//', '/'), { replace: true })
  }, [selectedContent, contentId, pathname])

  const onContentCreated = async (id: string) => {
    setShowDialog(false)
    setSelectedContent(id)
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event

    if (!active || !over) {
      return
    }

    if (active.id !== over.id) {
      const ids = contents.map((item) => item.id)
      const oldIndex = ids.indexOf(active.id as string)
      const newIndex = ids.indexOf(over.id as string)
      const newIds = arrayMove(ids, oldIndex, newIndex)

      await reorderContent({
        variables: {
          ids: newIds
        },
        optimisticResponse: {
          __typename: 'Mutation',
          reorderContents: newIds.map((id, index) => ({
            __typename: 'Content',
            ...contents.find((c) => c.id === id)!,
            order: index + 1
          }))
        }
      })
    }
  }

  return (<>
    <Box display="flex" flex={1} overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '250px',
          flex: '0 0 250px',
          borderRight: '1px solid #ccc'
        }}
      >
        <Box flex={1} overflow="auto">
          {!listItems.length
            ? (
              <Box display="flex" alignItems="center" justifyContent="center" color="#666" mt={2}>
                <ListIcon sx={{ mr: 1 }} />
                {t('edit.poi.noContent')}
              </Box>
              )
            : (
              <List sx={{ p: 0 }}>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                >
                  <SortableContext
                    items={contents.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {listItems}
                  </SortableContext>
                </DndContext>
              </List>
              )
        }
        </Box>
        <Box p={2} mt={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ color: '#fff', width: '100%' }}
            onClick={() => setShowDialog(true)}
          >
            {t('edit.poi.addContent')}
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
        {contentPanel}
      </Box>
    </Box>

    <CreateContentDialog
      modeId={modeId as string}
      nuggetId={nuggetId as string}
      show={showDialog}
      onClose={() => setShowDialog(false)}
      onCreated={onContentCreated}
    />

    <ConfirmDialog
      open={!!contentIdToDelete}
      text={t('edit.content.confirmDeleteContent')}
      onConfirm={onConfirmDelete}
      onCancel={() => setContentIdToDelete('')}
    />
  </>)
}

export default PoiNuggetContent
