import { Box, Button, Chip, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { UpdateContentInput } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { hi } from 'date-fns/locale'

import { deleteTexts } from '../mutation-helper/text'
import { updateContent } from '../mutation-helper/content'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys, EditableFile } from '../components/InteractionCardEditor'
import { uploadFile } from '../mutation-helper/file'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { quizConfigSchema } from '../config-schemas/default/quiz'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateQuizContentFormInput = InteractionCardFormInputs & {
  texts: {
    key: string,
    value: string,
  }[],
  backgroundImage: EditableFile,
  blockedById: string | null,
  hidden: boolean,
  style: string,
  config: string
}

export const QuizContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedTextKeys, setDeletedTextKeys] = useState<string[]>([])

  const texts = useMemo(() => {
    const textKeys = Object.keys(content.texts).filter((key) => !interactionCardTextKeys.includes(key))

    return textKeys.length
      ? textKeys.map(key => ({
        key,
        value: content.texts[key]
      }))
      : [{
          key: '',
          value: ''
        }]
  }, [content.texts])

  const getDefaultValues = () => {
    return {
      interactionCardTexts: interactionCardTextKeys.map((key) => ({
        key,
        value: content.texts[key] || ''
      })),
      descriptionImage: {
        key: 'description',
        file: content.files.find((file) => file.key === 'description') || null,
        replaceId: null
      },
      backgroundImage: {
        key: 'background',
        file: content.files.find((file) => file.key === 'background') || null,
        replaceId: null
      },
      texts,
      blockedById: content.blockedById || null,
      hidden: content.hidden ?? false,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateQuizContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields: textFields, append: appendText, remove: removeText } = useFieldArray({
    control: methods.control,
    name: 'texts'
  })

  const deleteText = (index: number, key: string) => {
    removeText(index)
    setDeletedTextKeys((prev) => [...prev, key])
  }

  const textInputs = useMemo(() => {
    return textFields.map((textField, index) => (
      <Box display="flex" key={index} gap={2}>
        <Controller
          control={methods.control}
          name={`texts.${index}.key`}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error: keyError } }) =>
            <TextField
              value={value}
              onChange={onChange}
              disabled={content.texts[textField.key] !== undefined}
              error={!!keyError}
              sx={{ flex: 1 }}
              label={t('common.key')}
              InputLabelProps={{ shrink: true }}
              helperText={!!keyError && t('common.required')}
            />
            }
        />
        <Controller
          control={methods.control}
          name={`texts.${index}.value`}
          rules={{ required: true }}
          render={({ field: { onChange }, fieldState: { error: valueError } }) =>
            <TextField
              value={methods.watch(`texts.${index}.value`)}
              onChange={onChange}
              error={!!valueError}
              sx={{ flex: 1 }}
              label={t('common.text')}
              InputLabelProps={{ shrink: true }}
              helperText={!!valueError && t('common.required')}
            />}
        />
        <IconButton onClick={() => deleteText(index, textField.key)}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Box>
    ))
  }, [textFields])

  const onSubmit = async (submittedData: UpdateQuizContentFormInput) => {
    setLoading(true)

    if (submittedData.backgroundImage.file && !(submittedData.backgroundImage.file as any)?.id) {
      try {
        await uploadFile({
          file: submittedData.backgroundImage.file,
          data: {
            key: 'background',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    if (submittedData.descriptionImage.file && !(submittedData.descriptionImage.file as any)?.id) {
      try {
        await uploadFile({
          file: submittedData.descriptionImage.file,
          data: {
            key: 'description',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    const updateTextVariables = submittedData.texts.concat(submittedData.interactionCardTexts)
      .filter(text => !!text.value)
      .map((text) => convertToTextObject(text.key, text.value))

    const emptyTextsKeys = updateTextVariables.filter(text => !text.value).map((text) => text.key)

    if (deletedTextKeys.length || emptyTextsKeys.length) {
      const deletedTextsVariables = deletedTextKeys.concat(emptyTextsKeys).map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      hidden: submittedData.hidden,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: updateTextVariables.filter(text => !!text.value)
    }

    try {
      await updateContent(content.id, data)
      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const addImage = (file: File) => {
    methods.setValue('backgroundImage.file', file, { shouldDirty: true })
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <InteractionCardEditor content={content} />
        <Box>
          <Chip label="Hintergrund" size="small" sx={{ marginBottom: '8px' }} />
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="200px"
            preview
            onFileChanged={addImage}
          >
            {!content?.files.find((file) => file.key === 'background')
              ? <Typography>{t('edit.poi.dragImage')}</Typography>
              : <Box width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${content.files.find((file) => file.key === 'background')?.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center'
                  }}
              />}
          </FileDragDrop>
        </Box>
        <ConfigAndStyleEditor content={content} schema={quizConfigSchema} />
        <Divider />
        {textInputs}
        <Box width="600xpx">
          <Button
            variant="outlined"
            sx={{ my: 0.5 }}
            onClick={() => appendText({ key: '', value: '' })}
          >
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty && methods.formState.isValid}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
