import React, { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { UpdateContentInput } from '@typings/graphql'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { Box, Chip, Stack } from '@mui/material'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { hi } from 'date-fns/locale'

import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentFile, replaceContentFiles, uploadContentFiles } from '../mutation-helper/file'
import { updateContent } from '../mutation-helper/content'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { MediaInfos } from '../components/MediaInfos'
import { comparisonSliderConfigSchema } from '../config-schemas/default/comparisonSlider'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type FileFormInput = {
  file: ContentFile | File | undefined,
  replaceId: string | null,
  key: string
}

type FileKey = 'imageFile' | 'overlayFile'

type UpdateComparisonSliderFormInput = {
  imageFile: FileFormInput,
  overlayFile: FileFormInput,
  hidden: boolean,
  blockedById: string | null,
  style: string,
  config: string
}

export const ComparisonSliderEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getDefaultValues = () => {
    return {
      imageFile: {
        file: [...content.files].find((file) => file.key === 'image'),
        replaceId: null,
        key: 'image'
      },
      overlayFile: {
        file: [...content.files].find((file) => file.key === 'overlay'),
        replaceId: null,
        key: 'overlay'
      },
      hidden: content.hidden ?? false,
      blockedById: content.blockedById || null,
      style: JSON.stringify(content.style, null, '\t') || '',
      config: JSON.stringify(content.config, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateComparisonSliderFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const replaceFile = (newFile: File, oldFile: ContentFile, key: FileKey) => {
    methods.setValue(`${key}.file`, newFile, { shouldDirty: true })

    if (oldFile?.id) {
      methods.setValue(`${key}.replaceId`, oldFile.id)
    }
  }

  const image = useWatch({
    control: methods.control,
    name: 'imageFile.file'
  })

  const overlay = useWatch({
    control: methods.control,
    name: 'overlayFile.file'
  })

  const onSubmit = async (submittedData: UpdateComparisonSliderFormInput) => {
    setLoading(true)

    const fileFormInputs = (['imageFile', 'overlayFile'] as FileKey[]).map((key) => submittedData[key])

    const replaceFileVariables = fileFormInputs.filter((fileFormInput) => !!fileFormInput.replaceId).map((fileFormInput) => ({
      file: fileFormInput.file,
      data: {
        key: fileFormInput.key,
        replaceId: fileFormInput.replaceId,
        model: 'Content',
        modelId: content.id
      }
    }))

    if (replaceFileVariables.length) {
      try {
        await replaceContentFiles(replaceFileVariables)
      } catch (e) {
        setError(e)
      }
    }

    const newFilesUploadVariables = fileFormInputs
      .filter((fileFormInput) => !(fileFormInput.file as any)?.id && !fileFormInput.replaceId)
      .map((fileFormInput) => ({
        file: fileFormInput.file,
        data: {
          key: fileFormInput.key,
          model: 'Content',
          modelId: content.id
        }
      }))

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      hidden: submittedData.hidden,
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: []
    }

    try {
      await updateContent(content.id, data)

      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box display="flex" gap={2}>
          <Box flex={1}>
            <Chip label="Image" size="small" sx={{ marginBottom: '12px' }} />
            <FileDragDrop
              accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
              height="300px"
              preview
              onFileChanged={file => replaceFile(file, methods.getValues('imageFile.file') as ContentFile, 'imageFile')}
            >
              {(image as any)?.url && <><Box width="100%"
                height="100%"
                sx={{
                  backgroundImage: `url(${(image as ContentFile).url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center center'
                }}
              />
                <MediaInfos file={methods.getValues('imageFile.file') as ContentFile}></MediaInfos>
              </>}
            </FileDragDrop>
          </Box>
          <Box flex={1}>
            <Chip label="Overlay" size="small" sx={{ marginBottom: '12px' }} />
            <FileDragDrop
              accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
              height="300px"
              preview
              style={{ backgroundColor: 'rgba(80, 80, 80, 0.3)' }}
              onFileChanged={file => replaceFile(file, methods.getValues('overlayFile.file') as ContentFile, 'overlayFile')}
            >
              {(overlay as any)?.url && <><Box width="100%"
                height="100%"
                sx={{
                  backgroundImage: `url(${(overlay as ContentFile).url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center center'
                }}
              />
                <MediaInfos file={methods.getValues('overlayFile.file') as ContentFile}></MediaInfos>
              </>}
            </FileDragDrop>
          </Box>
        </Box>
        <ConfigAndStyleEditor content={content} schema={comparisonSliderConfigSchema} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
