import React, { useState, useEffect } from 'react'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { UpdateContentInput } from '@typings/graphql'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { EditableFile, InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { syncContentTexts } from '../mutation-helper/text'
import { uploadFile } from '../mutation-helper/file'
import { updateContent } from '../mutation-helper/content'
import { cameraViewConfigSchema } from '../config-schemas/default/cameraView'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateCameraViewContentFormInput = InteractionCardFormInputs & {
  overlay: EditableFile,
  hidden: boolean
  config: string
  style: string
}

export const CameraViewContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getDefaultValues = (): UpdateCameraViewContentFormInput => ({
    interactionCardTexts: interactionCardTextKeys.map((key) => ({
      key,
      value: content.texts[key] || ''
    })),
    hidden: content.hidden ?? false,
    descriptionImage: {
      key: 'description',
      file: content.files.find((file) => file.key === 'description') || null,
      replaceId: null
    },
    overlay: {
      key: 'overlay',
      file: content.files.find((file) => file.key === 'overlay') || null,
      replaceId: null
    },
    config: JSON.stringify(content.config, null, '\t') || '',
    style: JSON.stringify(content.style, null, '\t') || ''
  })

  const methods = useForm<UpdateCameraViewContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const addImage = (file: File) => {
    methods.setValue('overlay', {
      key: 'overlay',
      file,
      replaceId: content.files.find((f) => f.key === 'overlay')?.id || null
    }, { shouldDirty: true })
  }

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    setLoading(true)

    const translatableTexts = submittedData.interactionCardTexts.reduce((acc, curr) => {
      if (curr.value) {
        acc[curr.key] = curr.value
      }

      return acc
    }, {} as Record<string, string>)

    const texts = await syncContentTexts(content, translatableTexts)

    if (submittedData.overlay.file && !(submittedData.overlay.file as any)?.id) {
      try {
        await uploadFile({
          file: submittedData.overlay.file,
          data: {
            key: 'overlay',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (submittedData.descriptionImage.file && !(submittedData.descriptionImage.file as any)?.id) {
      try {
        await uploadFile({
          file: submittedData.descriptionImage.file,
          data: {
            key: 'description',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (methods.formState.isDirty) {
      const data: UpdateContentInput = {
        nuggetId,
        order: content.order,
        type: content.type,
        hidden: submittedData.hidden,
        style: JSON.parse(submittedData.style),
        config: JSON.parse(submittedData.config),
        texts
      }

      try {
        await updateContent(content.id, data)
        methods.reset()
      } catch (e) {
        setUploadError(e)
      }

      setLoading(false)
    }

    setShowSnackbar(true)
    refetch?.()
  })

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <InteractionCardEditor content={content} />
        <Divider />
        <Box>
          <Chip label="Overlay image" size="small" sx={{ mb: 1 }} />
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="200px"
            preview
            onFileChanged={(file) => addImage(file)}
          >
            {!content?.files[0]
              ? <Typography>{t('edit.poi.dragImage')}</Typography>
              : <img
                  width="100%"
                  height="100%"
                  src={content.files[0].url}
                  alt={content.files[0].key}
                  style={{ objectFit: 'contain' }}
              />}
          </FileDragDrop>
        </Box>
        <Box marginY="64px">
          <ConfigAndStyleEditor content={content} schema={cameraViewConfigSchema} />
        </Box>
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={() => setShowSnackbar(false)}
      />
    </form>
  </FormProvider>
}
