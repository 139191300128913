import FileDragDrop from '@features/cms/components/FileDragDrop'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Chip, Typography } from '@mui/material'
import { UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { hi } from 'date-fns/locale'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { isGraphQlFile } from '../mutation-helper/file'
import { MediaInfos } from '../components/MediaInfos'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

const textKeys = ['text', 'blocked'] as const
type UpdateVoiceoverContentFormInput = {
  texts: Record<typeof textKeys[number], string>,
  blockedById: string | null,
  hidden: boolean
}

export const VoiceoverContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [voiceoverFile, setVoiceoverFile] = useState<File | null>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo(() => {
    return {
      blockedById: content.blockedById || null,
      hidden: content.hidden ?? false,
      texts: content.texts
    }
  }, [content])

  const methods = useForm<UpdateVoiceoverContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    setCanSave(!!voiceoverFile || methods.formState.isDirty)
    onEdited?.(!!voiceoverFile || methods.formState.isDirty)
  }, [methods.formState.isDirty, voiceoverFile])

  const addFile = (file: File | null) => setVoiceoverFile(file)

  const onSubmit = async (submittedData: UpdateVoiceoverContentFormInput) => {
    const translatableTexts = textKeys.reduce((acc, key) => {
      if (submittedData.texts[key]) {
        acc[key] = submittedData.texts[key]
      }

      return acc
    }, {} as Record<string, string>)

    const texts = await syncContentTexts(content, translatableTexts)

    if (voiceoverFile) {
      await uploadFile({
        variables: {
          file: voiceoverFile,
          data: {
            key: 'voiceover',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        }
      })

      setVoiceoverFile(undefined)
    }

    if (methods.formState.isDirty) {
      setLoading(true)

      const data: UpdateContentInput = {
        nuggetId,
        texts,
        order: content.order,
        type: content.type,
        blockedById: submittedData.blockedById || null,
        hidden: submittedData.hidden
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setUploadError(e)
      }
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <Box>
            <Chip label="text" size="small" sx={{ marginBottom: '8px' }} />
            <MarkdownEditor name="texts.text" />
          </Box>

          <Box>
            <Chip label={content.files[0]?.key || 'voiceover'} size="small" sx={{ marginBottom: '8px' }} />
            <FileDragDrop
              accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
              preview
              height={150}
              initialFile={isGraphQlFile(content.files[0]) ? content.files[0] : undefined}
              onFileChanged={addFile}
            >
              <Typography>{!content?.files.length ? t('edit.poi.dragAudio') : content.files[0].fileName}</Typography>
              <MediaInfos file={ content?.files[0] }></MediaInfos>
            </FileDragDrop>
          </Box>

          <ContentBlockEditor content={content} />
          <VisibilityEditor blocked={blocksContent} />
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
