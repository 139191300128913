import React, { useEffect, useState } from 'react'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { FormProvider, useForm } from 'react-hook-form'
import { convertToTextObject } from '@utils/texts'
import { UpdateContentInput } from '@typings/graphql'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Chip, Stack } from '@mui/material'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateMarkdownContentFormInput = {
  text: string
  hidden: boolean
  config: string
  style: string
}

export const MarkdownContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, onEdited }) => {
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const textKeys = Object.keys(content.texts)

  const getDefaultValues = () => {
    const values: Record<string, string> = {}

    textKeys.forEach((key) => {
      values[key] = content.texts[key]
    })

    return {
      text: content.texts.text,
      hidden: content.hidden ?? false,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateMarkdownContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = async (submittedData: UpdateMarkdownContentFormInput) => {
    const translatableTexts = [convertToTextObject('text', submittedData.text)]

    const data: UpdateContentInput = {
      nuggetId,
      texts: translatableTexts,
      order: content.order,
      type: content.type,
      hidden: submittedData.hidden,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style)
    }
    setLoading(true)

    try {
      await updateContent(content.id, data)

      methods.reset()
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setUploadError(null)
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <Chip label="text" size="small" sx={{ marginBottom: '8px' }} />
          <MarkdownEditor
            name="text"
            style={{ marginBottom: '60px' }}
          />
        </Box>
        <ConfigAndStyleEditor content={content} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
