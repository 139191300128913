import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { TourPoiQuery, useCloneContentNuggetMutation } from '@typings/graphql'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

type Section = TourPoiQuery['tourPoi']['sections'][0]
type Nugget = Section['nuggets'][0]

type Props = {
  poiId: string
  section: Section
  nugget: Nugget
  onDuplicate: () => void
}

const NuggetCard: React.FC<Props> = ({ poiId, section, nugget, onDuplicate }) => {
  const navigate = useNavigate()
  const { id, modeId } = useParams()
  const [duplicateNuggetMutation] = useCloneContentNuggetMutation()

  const cardItem = nugget || section
  const navigateTo = `/tour/${id}/mode/${modeId}/poi/${poiId}/section/${section.id}/nugget/${nugget.id}`

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id: nugget.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const duplicateNugget = async (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    await duplicateNuggetMutation({
      variables: {
        id: nugget.id
      }
    })

    onDuplicate()
  }

  return (<Box ref={setNodeRef} {...attributes} style={style}>
    <Card
      sx={{
        width: '250px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        marginRight: '32px',
        marginBottom: '32px',
        borderRadius: '15px',
        opacity: nugget.hidden ? 0.3 : 1
      }}
      onClick={() => navigate(navigateTo)}
    >
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        padding: 0
      }}
      >
        <Box
          sx={{
            backgroundImage: `url(${cardItem?.keyvisual?.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '150px'
          }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '12px'
        }}
        >
          <Typography
            textAlign="center"
            fontWeight="600"
            variant="h6"
            marginBottom="12px"
            sx={{ color: '#000', textDecoration: nugget.hidden ? 'line-through' : 'none' }}
          >
            {nugget?.texts.title || section.title}
          </Typography>
          {nugget?.texts.subtitle && <Typography textAlign="center">
            {nugget.texts.subtitle}
          </Typography>}
        </Box>
        <Box flexDirection="row">
          <IconButton onClick={(e: React.MouseEvent) => duplicateNugget(e)} size='medium'>
            <ContentCopyIcon />
          </IconButton>
          <IconButton>
            <DragIndicatorIcon {...listeners}
              ref={setActivatorNodeRef as any}
              sx={{ cursor: 'move' }}
            />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  </Box>)
}

export default NuggetCard
