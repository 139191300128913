import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TranslatableTextInput, UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { convertToTextObject } from '@utils/texts'
import { Stack, Box, Chip, Typography, TextField } from '@mui/material'

import { updateContent } from '../mutation-helper/content'
import FileDragDrop from '../../FileDragDrop'
import { FormFooterBar } from '../../ui/FormFooterBar'
import { ConfigEditor } from '../components/ConfigEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { ContentFile } from '../mutation-helper/file'
import { MarkdownEditor } from '../../MarkdownEditor'
import { audioMemoConfigSchema } from '../config-schemas/default/audioMemo'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateAudioMemoContentFormInput = {
  title: string
  text: string
  hidden: boolean
  config: string
  style: string
}

export const AudioMemoContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [voiceoverFile, setVoiceoverFile] = useState<File>()
  const [backgroundImageFile, setBackgroundImageFile] = useState<File>()

  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo(() => {
    return {
      title: content.texts.title,
      text: content.texts.text,
      hidden: content.hidden,
      config: JSON.stringify(content.config, null, '\t') || '',
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }, [content])

  const methods = useForm<UpdateAudioMemoContentFormInput>({
    defaultValues
  })

  useEffect(() => {
    setCanSave(!!voiceoverFile || !!backgroundImageFile || methods.formState.isDirty)
    onEdited?.(!!voiceoverFile || !!backgroundImageFile || methods.formState.isDirty)
  }, [methods.formState.isDirty, voiceoverFile])

  useUnsavedChangesAlert(methods.formState.isDirty || !!voiceoverFile || !!backgroundImageFile)

  const addVoiceover = (file: File) => setVoiceoverFile(file)

  const addBackgroundImage = (file: File) => setBackgroundImageFile(file)

  const onSubmit = async (submittedData: UpdateAudioMemoContentFormInput) => {
    if (voiceoverFile) {
      await uploadFile({
        variables: {
          file: voiceoverFile,
          data: {
            key: 'voiceover',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        }
      })

      setVoiceoverFile(undefined)
    }

    if (backgroundImageFile) {
      await uploadFile({
        variables: {
          file: backgroundImageFile,
          data: {
            key: 'background',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        }
      })

      setBackgroundImageFile(undefined)
    }

    const translatableTexts = [] as TranslatableTextInput[];

    ['title', 'text'].forEach(key => {
      if (submittedData[key as keyof UpdateAudioMemoContentFormInput]) {
        translatableTexts.push(convertToTextObject(key, submittedData[key as keyof UpdateAudioMemoContentFormInput]))
      }
    })

    if (methods.formState.isDirty) {
      setLoading(true)

      const data: UpdateContentInput = {
        nuggetId,
        texts: translatableTexts,
        order: content.order,
        type: content.type,
        hidden: submittedData.hidden,
        config: JSON.parse(submittedData.config)
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setUploadError(e)
      }
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  }

  const voiceover = useMemo(() => {
    return content.files.find((file: ContentFile) => file.key === 'voiceover')
  }, [content.files])

  const backgroundImage = useMemo(() => {
    return content.files.find((file: ContentFile) => file.key === 'background')
  }, [content.files])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <TextField
            sx={{ marginBottom: '12px', widht: '500px' }}
            label="title"
            {...methods.register('title')}
          />

          <Box>
            <Chip label="text" size="small" sx={{ marginBottom: '8px' }} />
            <MarkdownEditor
              name="text"
              style={{ marginBottom: '60px' }}
            />
          </Box>

          <Box>
            <Chip label='voiceover' size="small" sx={{ marginBottom: '8px' }} />
            <FileDragDrop
              accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
              preview
              onFileChanged={(file) => addVoiceover(file)}
            >
              <Typography>{ !voiceover ? t('edit.poi.dragAudio') : voiceover.fileName}</Typography>
            </FileDragDrop>
          </Box>

          <Box>
            <Chip label='background' size="small" sx={{ marginBottom: '8px' }} />
            <FileDragDrop
              accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
              preview
              onFileChanged={(file) => addBackgroundImage(file)}
            >
              <Typography>{ !backgroundImage ? t('edit.poi.dragImage') : backgroundImage.fileName}</Typography>
            </FileDragDrop>
          </Box>

          <ConfigEditor schema={audioMemoConfigSchema} />
          <ContentBlockEditor content={content} />
          <VisibilityEditor blocked={blocksContent} />
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
