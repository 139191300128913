import { Box } from '@mui/material'
import { useContentQuery, useTourModeQuery } from '@typings/graphql'
import React, { useMemo } from 'react'
import { ContentTypeEditors } from '@features/cms/components/content-type-editors'
import { SpriteVoiceoverProvider } from '@features/cms/provider/SpriteVoiceoverProvider'

type Props = {
  nuggetId: string,
  contentId?: string,
  modeId: string,
  blocksContent: boolean,
  refetch?: () => void
  onEdited?: (edited: boolean) => void
}

export const EditContentPanel: React.FC<Props> = ({
  nuggetId,
  contentId,
  modeId,
  blocksContent,
  refetch,
  onEdited
}) => {
  const { data: modeData } = useTourModeQuery({ variables: { id: modeId as string } })
  const { data } = useContentQuery({ variables: { id: contentId as string }, skip: !contentId })

  const content = useMemo(() => data?.content, [data])

  const editor = useMemo(() => {
    if (!modeData || !content) return null

    const modeType = modeData.tourMode.type
    const editors = ContentTypeEditors[modeType as keyof typeof ContentTypeEditors] ?? ContentTypeEditors.default
    const Editor = editors[content.type as keyof typeof editors]

    if (!Editor) return null

    return <Editor
      key={content.id}
      nuggetId={nuggetId}
      content={content}
      blocksContent={blocksContent}
      refetch={refetch}
      onEdited={onEdited}
    />
  }, [ContentTypeEditors, modeData, content])

  return <Box sx={{ flex: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
    <SpriteVoiceoverProvider>
      {editor}
    </SpriteVoiceoverProvider>
  </Box>
}
