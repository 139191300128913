import { Avatar, Box, Card, CardContent, Chip, IconButton, Typography } from '@mui/material'
import { TourPoiQuery, useCloneContentSectionMutation } from '@typings/graphql'
import React from 'react'
import DemoIcon from '@mui/icons-material/VisibilityOutlined'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

type Section = TourPoiQuery['tourPoi']['sections'][0]

type Props = {
  poiId: string
  section: Section
  onDuplicate: () => void
}

const SectionCard: React.FC<Props> = ({ poiId, section, onDuplicate }) => {
  const navigate = useNavigate()
  const { id, modeId } = useParams()
  const { t } = useTranslation()
  const [duplicateSectionMutation] = useCloneContentSectionMutation()

  const navigateTo = `/tour/${id}/mode/${modeId}/poi/${poiId}/section/${section.id}`

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id: section.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const duplicateSection = async (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    await duplicateSectionMutation({
      variables: {
        id: section.id
      }
    })

    onDuplicate()
  }

  return <Box ref={setNodeRef} {...attributes} style={style}>
    <Card
      sx={{
        width: '250px',
        aspectRatio: '2/3',
        backgroundColor: `${section.config.bgColor}`,
        '&:hover': {
          backgroundColor: `${section.config.cardColor}`
        },
        cursor: 'pointer',
        marginRight: '32px',
        marginBottom: '32px',
        padding: '12px',
        borderRadius: '15px',
        opacity: section.hidden ? 0.3 : 1
      }}
      onClick={() => navigate(navigateTo)}
    >
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        position: 'relative'
      }}
      >
        {section.inDemo && <Chip icon={<DemoIcon />}
          label={t('common.demo')}
          style={{
            backgroundColor: '#fff9',
            borderBottom: '1px solid #ccc',
            borderLeft: '1px solid #ccc',
            position: 'absolute',
            top: -12,
            right: -12,
            padding: '8px',
            borderStartStartRadius: 0,
            borderEndEndRadius: 0
          }}
        />}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden'
        }}
        >
          <Avatar
            sx={{
              width: 24,
              height: 24,
              backgroundColor: `${section.config.cardColor}`,
              color: '#fff',
              marginBottom: '8px',
              fontSize: '12px'
            }}
          >
            {section.order}
          </Avatar>
          <Typography
            textAlign="center"
            fontWeight="600"
            variant="h6"
            marginBottom="32px"
            sx={{ color: '#000', textDecoration: section.hidden ? 'line-through' : 'none' }}
            textOverflow="ellipsis"
            width="100%"
            overflow="hidden"
          >
            {section.title}
          </Typography>
        </Box>
        {section.keyvisual &&
          <Box
            sx={{
              backgroundImage: `url(${section.keyvisual.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              width: '150px',
              height: '150px',
              borderRadius: '30px',
              border: `3px solid ${section.config.cardColor}`
            }}
          />}
        <Box flexDirection="row">
          <IconButton onClick={(e: React.MouseEvent) => duplicateSection(e)} size='medium'>
            <ContentCopyIcon />
          </IconButton>
          <IconButton>
            <DragIndicatorIcon {...listeners}
              ref={setActivatorNodeRef as any}
              sx={{ cursor: 'move' }}
            />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  </Box>
}

export default SectionCard
