import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import {
  useTourFullQuery, TourPoiFullFragment, useUpdateTourPoiMutation,
  useReorderTourPoisMutation, TourFullQuery
} from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'
import ListIcon from '@mui/icons-material/List'
import {
  Box, Button, Divider, IconButton, InputLabel, List, ListItem,
  ListItemText, MenuItem, Select, Typography
} from '@mui/material'
import Map, { MapLayerMouseEvent, MapRef, Marker, NavigationControl, Popup } from 'react-map-gl'
import { PoiCreateDialog } from '@features/cms/components/dialogs/PoiCreateDialog'
import { useTranslation } from 'react-i18next'
import { bbox, multiPoint } from '@turf/turf'
import EditIcon from '@mui/icons-material/Edit'
import PinDropIcon from '@mui/icons-material/PinDrop'
import './ModePois.css'
import { useAppDispatch, useAppSelector } from '@services/store'
import { setMapStyle, setMapViewState, setPoiView } from '@services/store/slices/cms'
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'

const MAP_STYLES = {
  basic: import.meta.env.REACT_APP_MAPBOX_STYLE_URL_BASIC,
  streets: import.meta.env.REACT_APP_MAPBOX_STYLE_URL_STREETS
}

const getBBoxForCoordinates = (
  pois: {
    lat: number;
    lng: number;
  }[]
) => {
  const mp = multiPoint(pois.map(({ lat, lng }) => [lng, lat]))
  return bbox(mp)
}

type ItemProps = {
  poi: TourFullQuery['tour']['mode']['pois'][number],
  onEdit: () => void
}

const PoiListItem: React.FC<ItemProps> = ({
  poi,
  onEdit
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id: poi.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <ListItem
        key={poi.id}
        sx={{ mb: 1, justifyContent: 'flex-start' }}
        secondaryAction={
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <ListIcon ref={setActivatorNodeRef as any} {...listeners} sx={{ cursor: 'move', color: '#666', ml: 2 }} />
          </Box>
      }
        disablePadding
      >
        <ListItemText
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textDecoration: poi.hidden ? 'line-through' : undefined,
            pr: 4,
            opacity: poi.hidden ? 0.3 : 1
          }}
          id={poi.id}
        >
          {poi.title}
        </ListItemText>
      </ListItem>
    </div>
  )
}

const ModePois: React.FC = () => {
  const refMap = useRef<MapRef | null>(null)
  const refMapBoxContainer = React.useRef<HTMLDivElement>(null)

  const { id, modeId } = useParams()
  const { data, refetch } = useTourFullQuery({ variables: { id: id as string, modeId: modeId as string } })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [updatePoi] = useUpdateTourPoiMutation()

  const [mapLoaded, setMapLoaded] = useState(false)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const mapStyle = useAppSelector(state => state.cms.modeMapStyle?.[modeId as string] || MAP_STYLES.basic)
  const poiView = useAppSelector(state => state.cms.modePoiView?.[modeId as string] || 'map')
  const [selectedPoiView, setSelectedPoiView] = useState<'list' | 'map'>(poiView)
  const [selectedMapStyle, setSelectedMapStyle] = useState(mapStyle)
  const [selectedLngLat, setSelectedLngLat] = useState<{ lng: number, lat: number } | null>(null)
  const mapZoom = useAppSelector(state => state.cms.modeMapZoom[modeId as string])
  const [viewState, setViewState] = useState({
    latitude: 50.5558,
    longitude: 9.6808,
    zoom: mapZoom || 14
  })
  const [reorderPois] = useReorderTourPoisMutation()

  const tour = data?.tour
  const mode = tour?.mode

  const poisOnMap = useMemo(() => (mode?.pois || []).filter((poi) => poi.lat && poi.lng), [mode])
  const unplacedPois = useMemo(() => (mode?.pois || []).filter((poi) => !poi.lat || !poi.lng), [mode])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const updateMapBounds = useCallback(() => {
    if (!poisOnMap.length) {
      return
    }

    const bb = getBBoxForCoordinates(
      poisOnMap.map((poi) => ({ lat: poi.lat, lng: poi.lng }))
    )

    refMap.current?.fitBounds(
      [[bb[0], bb[1]], [bb[2], bb[3]]],
      {
        padding: { left: 50, right: 50, top: 150, bottom: 50 },
        animate: false,
        zoom: mapZoom || 14
      }
    )
  }, [poisOnMap])

  const onMapLoaded = useCallback(() => {
    setMapLoaded(true)

    updateMapBounds()
  }, [])

  useEffect(() => {
    if (!mapLoaded || !poisOnMap.length) {
      return
    }

    updateMapBounds()
  }, [mapLoaded, poisOnMap])

  useEffect(() => {
    dispatch(setMapViewState({ [modeId as string]: viewState.zoom }))
  }, [viewState])

  useEffect(() => {
    dispatch(setMapStyle({ [modeId as string]: selectedMapStyle }))
  }, [selectedMapStyle])

  useEffect(() => {
    dispatch(setPoiView({ [modeId as string]: selectedPoiView }))
  }, [selectedPoiView])

  useEffect(() => {
    refetch()
  }, [])

  const onPoiCreated = () => {
    refetch()
    setSelectedLngLat(null)
    setShowCreateDialog(false)
  }

  const openCreatePoiDialog = (e: MapLayerMouseEvent, lngLat: { lng: number, lat: number }) => {
    e.preventDefault()
    setSelectedLngLat(lngLat)
    setShowCreateDialog(true)
  }

  const closeCreatePoiDialog = () => {
    setSelectedLngLat(null)
    setShowCreateDialog(false)
  }

  const navigateToPoi = (poi: TourPoiFullFragment) => {
    navigate(`/tour/${id}/mode/${modeId}/poi/${poi.id}`)
  }

  const poiListItems = useMemo(() => poisOnMap.map((poi) => {
    return <PoiListItem
      key={poi.id}
      poi={poi}
      onEdit={() => navigateToPoi(poi)}
    />
  }), [poisOnMap])

  const onPoiMoved = async (lngLat: {lng: number, lat: number}, poiId: string) => {
    await updatePoi({
      variables: {
        id: poiId,
        data: {
          type: 'default',
          lat: lngLat.lat,
          lng: lngLat.lng
        }
      }
    })
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event

    if (!active || !over) {
      return
    }

    if (active.id !== over.id) {
      const ids = poisOnMap.map((item) => item.id)
      const oldIndex = ids.indexOf(active.id as string)
      const newIndex = ids.indexOf(over.id as string)
      const newIds = arrayMove(ids, oldIndex, newIndex)

      await reorderPois({
        variables: {
          poiIds: newIds,
          tourModeId: modeId as string
        },
        optimisticResponse: {
          reorderTourPois: newIds.map((poiId) => ({
            __typename: 'TourPoi',
            id: poiId
          }))
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({
              __typename: 'TourMode',
              id: modeId
            }),
            fields: {
              pois (existing, { readField }) {
                return [...existing].sort((a: any, b: any) => {
                  const aIndex = newIds.indexOf(readField('id', a) as string)
                  const bIndex = newIds.indexOf(readField('id', b) as string)

                  return aIndex - bIndex
                })
              }
            }
          })
        }
      })
    }
  }

  const poiMarkers = useMemo(() => {
    if (!mapLoaded) {
      return null
    }

    return poisOnMap.map((poi) => (
      <React.Fragment key={poi.id}>
        <Marker
          key={poi.id}
          color="secondary"
          latitude={poi.lat}
          longitude={poi.lng}
          draggable={true}
          onDragEnd={(e) => onPoiMoved(e.lngLat, poi.id)}
          style={{ opacity: poi.hidden ? 0.3 : 1 }}
        />
        <Popup
          longitude={poi.lng}
          latitude={poi.lat}
          closeButton={false}
          closeOnClick={false}
          anchor="bottom"
          offset={40}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography color="#999">{poi.order}.</Typography>
            <Typography>{poi.title}</Typography>

            <IconButton onClick={() => navigateToPoi(poi)} sx={{ borderRadius: 2 }}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
        </Popup>
      </React.Fragment>
    ))
  }, [mode, mapLoaded])

  if (!mode) {
    return null
  }

  return <Box p={4} flex={1} display="flex" flexDirection="column">
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography color="grey">{t('edit.mode.clickToEditPoi')}</Typography>
        <Typography color="grey">{t('edit.mode.doubleClickToCreatePoi')}</Typography>
      </Box>
      <Box>
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <InputLabel id="status-label">{t('edit.mode.poiView')}</InputLabel>
            <Select
              variant='standard'
              labelId="status-label"
              sx={{ width: '200px' }}
              value={selectedPoiView}
              label={t('edit.mode.poiView')}
              onChange={event => setSelectedPoiView(event.target.value as 'map' | 'list')}
            >
              <MenuItem value="map">{t('edit.mode.poiViewMap')}</MenuItem>
              <MenuItem value="list">{t('edit.mode.poiViewList')}</MenuItem>
            </Select>
          </Box>
          {selectedPoiView === 'map' && <Box mr={2}>
            <InputLabel id="status-label">{t('edit.mode.mapStyle')}</InputLabel>
            <Select
              variant='standard'
              labelId="status-label"
              sx={{ width: '200px' }}
              value={selectedMapStyle}
              label={t('edit.mode.mapStyle')}
              onChange={event => setSelectedMapStyle(event.target.value)}
            >
              <MenuItem value={MAP_STYLES.basic}>{t('edit.mode.mapStyleBasic')}</MenuItem>
              <MenuItem value={MAP_STYLES.streets}>{t('edit.mode.mapStyleStreets')}</MenuItem>
            </Select>
          </Box>}
          <Button
            variant='outlined'
            onClick={() => setShowCreateDialog(true)}
          >
            {t('edit.mode.createPoi')}
          </Button>
        </Box>
      </Box>
    </Box>
    <Box width="100%" display="flex" flex={1} mt={2.5} flexDirection="row" justifyContent="space-between">
      {unplacedPois.length > 0 && <Box width="35%">
        <Typography color="grey">{t('edit.mode.unplacedPois')}</Typography>
        <Box bgcolor="grey.50" mr={10} mt={1}>
          <List>
            {unplacedPois.map((poi, index) => (<ListItem key={poi.id}
              secondaryAction={
                <IconButton onClick={() => navigate(`/tour/${id}/mode/${modeId}/poi/${poi.id}`)}>
                  <EditIcon />
                </IconButton>
              }
            >
              <ListItemText primary={poi.title} />
              {index !== unplacedPois.length - 1 && <Divider />}
            </ListItem>))}
          </List>
        </Box>
      </Box>}
      <Box width={unplacedPois.length ? '60%' : '100%'} flex={1} ref={refMapBoxContainer} position="relative" overflow="auto">
        {selectedPoiView === 'map'
          ? <>
            <Map
              ref={refMap}
              {...viewState}
              onLoad={onMapLoaded}
              onMove={evt => setViewState(evt.viewState)}
              mapboxAccessToken={import.meta.env.REACT_APP_MAPBOX_TOKEN}
              style={{ width: '100%', height: '100%' }}
              mapStyle={selectedMapStyle}
              onDblClick={(e) => { openCreatePoiDialog(e, e.lngLat) }}
            >
              <NavigationControl showCompass={false} />
              {poiMarkers}
            </Map>
            <IconButton sx={{ position: 'absolute', top: 10, right: 10, bgcolor: '#fff9' }} onClick={() => updateMapBounds()}>
              <PinDropIcon />
            </IconButton>
          </>
          : <>
            <Typography color="grey">{t('edit.mode.pois')}</Typography>
            <Box bgcolor="grey.50" mr={10} mt={1} px={2} pt={1} overflow="auto">
              <List>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                >
                  <SortableContext
                    items={poisOnMap.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {poiListItems}
                  </SortableContext>
                </DndContext>
              </List>
            </Box>
          </>}
      </Box>
    </Box>
    <PoiCreateDialog
      open={showCreateDialog}
      lngLat={selectedLngLat}
      onPoiCreated={() => onPoiCreated()}
      onClosed={() => closeCreatePoiDialog()}
    />
  </Box>
}

export default ModePois
