import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, IconButton, InputLabel, TextField, Typography } from '@mui/material'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { convertToTextObject } from '@utils/texts'
import {
  useUpdateTourPoiMutation, useTourPoiQuery, useUploadFileMutation,
  File as GraphQLFile,
  useDisconnectTourPoiMutation,
  useTagsQuery, useCreateTagRelationsMutation, useDisconnectTagsMutation, PoiTagDataFragment
} from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import DeleteIcon from '@mui/icons-material/Delete'
import { ConfirmDialog } from '@features/cms/components/dialogs/ConfirmDialog'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { FileList } from '@features/cms/components/FileList'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { LoadingButton } from '@mui/lab'
import { ConfigEditor } from '@features/cms/components/content-type-editors/components/ConfigEditor'
import { ContentFile, isGraphQlFile } from '@features/cms/components/content-type-editors/mutation-helper/file'

import { CreatePoiCategoryDialog } from './dialogs/CreatePoiCategoryDialog'

type UpdateTourPoiFormInput = {
  type: string,
  title: string,
  subtitle: string,
  lat: string | number,
  lng: string | number,
  tags: PoiTagDataFragment[]
  keyvisual?: File | GraphQLFile | undefined | null,
  config?: Record<string, any>,
  hidden?: boolean
}

const coordinatePattern = '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'

const PoiEdit: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id, modeId, poiId } = useParams()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showEditCategoryDialog, setShowEditCategoryDialog] = useState(false)
  const [editCategoryId, setEditCategoryId] = useState<string>()

  const [updatePoi, { loading: isUpdating, error }] = useUpdateTourPoiMutation()
  const [uploadKeyvisual, { loading: isUploading, error: uploadError }] = useUploadFileMutation()
  const [createTagRelation, { loading: isCreatingTagRelation, error: createTagRelationError }] = useCreateTagRelationsMutation()
  const [disconnectTags] = useDisconnectTagsMutation()
  const [disconnectPoi] = useDisconnectTourPoiMutation()

  const { data: tagsData, loading: tagsLoading, refetch } = useTagsQuery()
  const allTags = useMemo(() => tagsData?.tags as PoiTagDataFragment[] ?? [], [tagsData])

  const { data } = useTourPoiQuery({ variables: { id: poiId as string } })
  const poi = data?.tourPoi

  const methods = useForm<UpdateTourPoiFormInput>({
    defaultValues: {
      type: poi?.type || 'default',
      title: 'Poi',
      subtitle: 'Untertitel',
      lat: poi?.lat ?? 1234,
      lng: poi?.lng ?? 1234,
      tags: poi?.tags,
      keyvisual: poi?.files.find((file) => file.key === 'keyvisual'),
      config: poi?.config || {},
      hidden: !!poi?.hidden
    }
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  const selectedTags = useWatch({
    control: methods.control,
    name: 'tags'
  })

  const updateTags = (tags: PoiTagDataFragment[]) => {
    methods.setValue('tags', tags, { shouldDirty: true })
  }

  useEffect(() => {
    if (!poi) return

    methods.reset({
      type: poi.type,
      title: poi.title,
      subtitle: poi.subtitle || '',
      lat: poi.lat,
      lng: poi.lng,
      tags: poi.tags,
      keyvisual: poi.files.find((file) => file.key === 'keyvisual'),
      hidden: poi.hidden
    })
  }, [poi])

  const editCategory = (categoryId: string) => {
    setEditCategoryId(categoryId)
    setShowEditCategoryDialog(true)
  }

  const onSubmit = async (submittedData: UpdateTourPoiFormInput) => {
    if (!poi) return

    const translatableTexts = [convertToTextObject('title', submittedData.title)]

    if (submittedData.subtitle) {
      translatableTexts.push(convertToTextObject('subtitle', submittedData.subtitle))
    }

    try {
      if (submittedData.keyvisual && !isGraphQlFile(submittedData.keyvisual)) {
        await uploadKeyvisual({
          variables: {
            file: submittedData.keyvisual,
            data: {
              key: 'keyvisual',
              replace: true,
              model: 'TourPoi',
              modelId: poi.id
            }
          }
        })
      }

      if (submittedData.tags.length || poi.tags.length) {
        const submittedTagIds = submittedData.tags.map(tag => tag.id)
        const newTagIds = (submittedTagIds).filter((tagId) => !poi.tags.map((tag) => tag.id).includes(tagId))
        const deletedTagIds = poi.tags.map((tag) => tag.id).filter((tagId) => !submittedTagIds.includes(tagId))

        if (newTagIds) {
          await createTagRelation({
            variables: {
              model: 'TourPoi',
              modelId: poi.id,
              tagIds: newTagIds
            }
          })
        }

        if (deletedTagIds) {
          await disconnectTags({
            variables: {
              model: 'TourPoi',
              modelId: poi.id,
              tagIds: deletedTagIds
            }
          })
        }
      }

      if (methods.formState.isDirty) {
        await updatePoi({
          variables: {
            id: poi.id,
            data: {
              type: submittedData.type,
              texts: translatableTexts,
              lat: parseFloat(submittedData.lat as string),
              lng: parseFloat(submittedData.lng as string),
              config: submittedData.config,
              hidden: submittedData.hidden
            }
          }
        })
      }
    } catch (e) {}

    methods.reset()
    setShowSnackbar(true)
  }

  const goBack = async () => {
    setTimeout(() => {
      navigate(`/tour/${id}/mode/${modeId}/pois`)
    }, 500)
  }

  const onConfirmDelete = async () => {
    try {
      await disconnectPoi({ variables: { id: poi!.id as string, modeId: modeId! } })

      navigate(`/tour/${id}/mode/${modeId}/pois`)
    } catch (e) {
      console.log(e)
    }
  }

  const onDialogClose = () => {
    setShowEditCategoryDialog(false)
    setEditCategoryId(undefined)
  }

  const onCategoryCreated = async (tag: PoiTagDataFragment) => {
    onDialogClose()
    await refetch()
    methods.setValue('tags', [...selectedTags, tag], { shouldDirty: true })
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  if (!poi) {
    return null
  }

  return (
    <FormProvider {...methods} >
      <form
        style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', flex: 1 }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box
          p={4}
          flex={1}
          sx={{ overflowY: 'auto' }}
          display="flex"
          flexDirection="row"
          gap={4}
        >
          <Box display="flex" flexDirection="column" flex={1} gap={4}>
            <TextField
              sx={{ width: '100%' }}
              label={t('common.type')}
              {...methods.register('type')}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              sx={{ width: '100%' }}
              label={t('common.title')}
              required
              InputLabelProps={{ shrink: true }}
              {...methods.register('title')}
            />
            <TextField
              sx={{ width: '100%' }}
              label={t('common.subtitle')}
              InputLabelProps={{ shrink: true }}
              {...methods.register('subtitle')}
            />
            <Box display="flex" flexDirection="row" gap={4}>
              <TextField
                sx={{ flex: 1 }}
                label={t('edit.poi.latitude')}
                required
                inputProps={{ pattern: coordinatePattern }}
                {...methods.register('lat')}
              />
              <TextField
                sx={{ flex: 1 }}
                label={t('edit.poi.longitude')}
                required
                inputProps={{ pattern: coordinatePattern }}
                {...methods.register('lng')}
              />
            </Box>
            <Controller
              name="hidden"
              control={methods.control}
              render={({ field: { onChange, value } }) => <FormControlLabel
                control={<Checkbox sx={{ py: 0 }} checked={value} onChange={e => onChange(e.currentTarget.checked)} />}
                label={t('common.hide')}
              />}
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} gap={4}>
            <TextField
              sx={{ width: '100%' }}
              label={t('common.materialIcon')}
              InputLabelProps={{ shrink: true }}
              {...methods.register('config.iconName')}
            />
            <TextField
              sx={{ width: '100%' }}
              label={t('common.svgIcon')}
              InputLabelProps={{ shrink: true }}
              {...methods.register('config.svgIcon')}
            />
            <Box>
              <InputLabel id="category-label">{t('common.categories')}</InputLabel>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                {!tagsLoading && (
                <Autocomplete
                  multiple
                  sx={{ flex: 1 }}
                  value={selectedTags}
                  options={allTags}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option?.texts?.name}
                  renderInput={(params) => <TextField {...params} />}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        label={option?.texts?.name}
                        {...getTagProps({ index })}
                        onClick={() => editCategory(option.id)}
                      />
                    ))
                  }
                  onChange={(_, newValue) => updateTags(newValue)}
                />
                )}
                <IconButton sx={{ ml: 2 }} onClick={() => setShowEditCategoryDialog(true)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>

              <Box mt={4}>
                <ConfigEditor />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              {poi && <Box>
                <FileList files={poi.files} model="TourPoi" modelId={poi.id} />
                </Box>}
            </Box>
          </Box>
          <Box flex={1}>
            <Controller
              name="keyvisual"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <FileDragDrop
                  accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
                  height="90%"
                  preview
                  initialFile={value as ContentFile}
                  onFileChanged={onChange}
                />
              )}
            />
          </Box>

        </Box>
        <FormFooterBar
          disabled={!methods.formState.isDirty}
          loading={isUpdating || isUploading || isCreatingTagRelation}
          uploadError={error || uploadError || createTagRelationError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        >
          <Button
            color="warning"
            variant="outlined"
            endIcon={<DeleteIcon />}
            onClick={() => setShowConfirmDialog(true)}
          >
            {t('edit.poi.delete')}
          </Button>

          <Box flex={100} />

          <LoadingButton
            variant="outlined"
            loading={isUpdating || isUploading || isCreatingTagRelation}
            color="primary"
            sx={{ mr: 2 }}
            onClick={goBack}
          >
            {t('edit.poi.goBack')}
          </LoadingButton>
        </FormFooterBar>

        <ConfirmDialog
          open={showConfirmDialog}
          text={t('edit.poi.confirmDelete')}
          onConfirm={onConfirmDelete}
          onCancel={() => setShowConfirmDialog(false)}
        />
        <CreatePoiCategoryDialog
          tagId={editCategoryId}
          open={showEditCategoryDialog}
          onClose={onDialogClose}
          onCreated={onCategoryCreated}
        />
      </form>
    </FormProvider>
  )
}

export default PoiEdit
