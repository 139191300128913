import { Box, Button, Divider, IconButton, Stack, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { UpdateContentInput } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { updateContent } from '../mutation-helper/content'
import { deleteTexts } from '../mutation-helper/text'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateInfoCarouselContentFormInput = {
  texts: {
    key: string,
    value: string,
  }[],
  blockedById: string | null,
  hidden: boolean,
  style: string,
  config: string
}

export const InfoCarouselContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedTextKeys, setDeletedTextKeys] = useState<string[]>([])

  const texts = useMemo(() => {
    return Object.keys(content.texts).length
      ? Object.keys(content.texts).map((key) => ({
        key,
        value: content.texts[key]
      }))
      : [{
          key: '',
          value: ''
        }]
  }, [content.texts])

  const getDefaultValues = () => {
    return {
      texts,
      blockedById: content.blockedById || null,
      hidden: content.hidden ?? false,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateInfoCarouselContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields: textFields, append: appendText, remove: removeText } = useFieldArray({
    control: methods.control,
    name: 'texts'
  })

  const deleteText = (index: number, key: string) => {
    removeText(index)
    setDeletedTextKeys((prev) => [...prev, key])
  }

  const textInputs = useMemo(() => {
    return textFields.map((textField, index) => (<Box key={index}>
      <Box
        display="flex"
        flexDirection="row"
        mb={4}
        mt={4}
      >
        <Box display="flex" flexDirection="column" mr={4}>
          <Controller
            control={methods.control}
            name={`texts.${index}.key`}
            rules={{ required: true }}
            render={({ field: { onChange }, fieldState: { error: keyError } }) =>
              <TextField
                value={methods.watch(`texts.${index}.key`)}
                onChange={onChange}
                disabled={content.texts[textField.key] !== undefined}
                error={!!keyError}
                sx={{ marginBottom: '16px', marginLeft: '18px', width: '500px' }}
                label={t('common.key')}
                InputLabelProps={{ shrink: true }}
                helperText={!!keyError && t('common.required')}
              />
            }
          />
          <Controller
            control={methods.control}
            name={`texts.${index}.value`}
            rules={{ required: true }}
            render={({ field: { onChange }, fieldState: { error: valueError } }) =>
              <TextField
                value={methods.watch(`texts.${index}.value`)}
                onChange={onChange}
                error={!!valueError}
                sx={{ marginBottom: 4, marginLeft: '18px', width: '500px' }}
                label={t('common.text')}
                InputLabelProps={{ shrink: true }}
                helperText={!!valueError && t('common.required')}
              />
            }
          />
        </Box>
        <Box height="56px">
          <IconButton onClick={() => deleteText(index, textField.key)}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </Box>))
  }, [textFields])

  const onSubmit = async (submittedData: UpdateInfoCarouselContentFormInput) => {
    setLoading(true)

    if (deletedTextKeys.length) {
      const deletedTextsVariables = deletedTextKeys.map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      hidden: submittedData.hidden,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: submittedData.texts.map(text => convertToTextObject(text.key, text.value)).filter(text => !!text.value)
    }

    try {
      await updateContent(content.id, data)
      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        {textInputs}
        <Box width="600xpx">
          <Button
            variant="outlined"
            sx={{ my: 4 }}
            onClick={() => appendText({ key: '', value: '' })}
          >
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <Divider />
        <ConfigAndStyleEditor content={content} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
