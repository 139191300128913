import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Stack, TextField } from '@mui/material'
import { UpdateContentInput } from '@typings/graphql'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { hi } from 'date-fns/locale'

import { InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { ContentEditorComponentProps } from '../default'
import { uploadFile } from '../mutation-helper/file'
import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { balanceCheckConfigSchema } from '../config-schemas/default/balanceCheck'
import { VisibilityEditor } from '../components/VisibilityEditor'

const textKeys = ['exerciseTitle', 'exerciseDescription', 'closeButtonText'] as const

type UpdateBalanceCheckContentFormInput = InteractionCardFormInputs & {
  texts: Record<typeof textKeys[number], string>,
  hidden: boolean,
  config: string
  style: string
}

export const BalanceCheckContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const defaultValues = useMemo(() => {
    return {
      interactionCardTexts: interactionCardTextKeys.map((key) => ({
        key,
        value: content.texts[key] || ''
      })),
      descriptionImage: {
        key: 'description',
        file: content.files.find((file) => file.key === 'description') || null,
        replaceId: null
      },
      hidden: content.hidden ?? false,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t'),
      texts: content.texts
    }
  }, [content])

  const methods = useForm<UpdateBalanceCheckContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = methods.handleSubmit(async (submittedData: UpdateBalanceCheckContentFormInput) => {
    setLoading(true)

    const translatableTexts = submittedData.interactionCardTexts.reduce((acc, curr) => {
      if (curr.value) {
        acc[curr.key] = curr.value
      }

      return acc
    }, {} as Record<string, string>)

    textKeys.forEach((key) => {
      if (submittedData.texts[key]) {
        translatableTexts[key] = submittedData.texts[key]
      }
    })

    const texts = await syncContentTexts(content, translatableTexts)

    if (submittedData.descriptionImage?.file && !(submittedData.descriptionImage.file as any).id) {
      try {
        await uploadFile({
          file: submittedData.descriptionImage.file,
          data: {
            key: 'description',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (methods.formState.isDirty) {
      const data: UpdateContentInput = {
        nuggetId,
        order: content.order,
        type: content.type,
        style: JSON.parse(submittedData.style),
        config: JSON.parse(submittedData.config),
        hidden: submittedData.hidden,
        texts
      }

      try {
        await updateContent(content.id, data)
        methods.reset()
      } catch (e) {
        setUploadError(e)
      }

      setLoading(false)
    }

    setShowSnackbar(true)
    refetch && refetch()
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <InteractionCardEditor content={content} />

        <Stack flexDirection="column" py="40px">
          <TextField
            sx={{ pb: '40px', width: '500px' }}
            label={t('edit.content.exerciseTitle')}
            {...methods.register('texts.exerciseTitle')}
          />
          <TextField
            sx={{ pb: '40px', width: '500px' }}
            label={t('edit.content.exerciseDescription')}
            {...methods.register('texts.exerciseDescription')}
          />
          <TextField
            sx={{ width: '500px' }}
            label={t('edit.content.closeButtonText')}
            {...methods.register('texts.closeButtonText')}
          />
        </Stack>
        <Box marginY="64px">
          <ConfigAndStyleEditor content={content} schema={balanceCheckConfigSchema} />
        </Box>
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
