import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button, Divider, IconButton, Stack, TextField } from '@mui/material'
import { UpdateContentInput } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'

import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { FormFooterBar } from '../../ui/FormFooterBar'
import { updateContent } from '../mutation-helper/content'
import { deleteTexts } from '../mutation-helper/text'
import { linkBoxConfigSchema } from '../config-schemas/default/linkBox'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateLinkBoxContentFormInput = {
  title: string,
  text: string,
  links: {
    key: string,
    value: string,
    svgIcon: string,
  }[],
  blockedById: string | null,
  hidden: boolean,
  style: string,
  config: string,
}

const staticTextKeys = ['title', 'text'] as const

export const LinkBoxContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedLinks, setDeletedLinks] = useState<string[]>([])

  const getDefaultValues = () => {
    const links = Object.keys(content.texts).filter((key) => key.includes('link')).map((key) => ({
      key,
      value: content.texts[key],
      svgIcon: content.config.icons[key]
    }))

    return {
      title: content.texts.title || '',
      text: content.texts.text || '',
      links,
      blockedById: content.blockedById || null,
      hidden: content.hidden ?? false,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateLinkBoxContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields: links, append: appendLink, remove: removeLink } = useFieldArray({
    control: methods.control,
    name: 'links'
  })

  const deleteLink = (index: number, key: string) => {
    removeLink(index)
    setDeletedLinks((prev) => [...prev, key])
  }

  const linkInputs = useMemo(() => {
    return links.map((linkInput, index) => (
      <Box display="flex" key={index} gap={2}>
        <Controller
          control={methods.control}
          name={`links.${index}.key`}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error: keyError } }) =>
            <TextField
              value={value}
              onChange={onChange}
              disabled={content.texts[linkInput.key] !== undefined}
              error={!!keyError}
              sx={{ flex: 1 }}
              label={t('common.key')}
              InputLabelProps={{ shrink: true }}
              helperText={!!keyError && t('common.required')}
            />
            }
        />
        <Controller
          control={methods.control}
          name={`links.${index}.value`}
          rules={{ required: true }}
          render={({ field: { onChange }, fieldState: { error: valueError } }) =>
            <TextField
              value={methods.watch(`links.${index}.value`)}
              onChange={onChange}
              error={!!valueError}
              sx={{ flex: 1 }}
              label={t('common.text')}
              InputLabelProps={{ shrink: true }}
              helperText={!!valueError && t('common.required')}
            />}
        />
        <Controller
          control={methods.control}
          name={`links.${index}.svgIcon`}
          rules={{ required: true }}
          render={({ field: { onChange }, fieldState: { error: valueError } }) =>
            <TextField
              value={methods.watch(`links.${index}.svgIcon`)}
              onChange={onChange}
              error={!!valueError}
              sx={{ flex: 1 }}
              label="SVG Icon"
              InputLabelProps={{ shrink: true }}
              helperText={!!valueError && t('common.required')}
            />}
        />
        <IconButton onClick={() => deleteLink(index, linkInput.key)}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Box>
    ))
  }, [links])

  const onSubmit = async (submittedData: UpdateLinkBoxContentFormInput) => {
    setLoading(true)

    const updateTextVariables = submittedData.links
      .map((link) => convertToTextObject(link.key, link.value))

    staticTextKeys.forEach((key) => {
      updateTextVariables.push(convertToTextObject(key, submittedData[key]))
    })

    const emptyTextsKeys = updateTextVariables.filter(text => !text.value).map((text) => text.key)

    const iconSvgs = submittedData.links.reduce((acc, curr) => {
      acc[curr.key] = curr.svgIcon
      return acc
    }, {} as Record<string, string>)

    if (deletedLinks.length || deletedLinks.length) {
      const deletedTextsVariables = deletedLinks.concat(emptyTextsKeys).map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      hidden: submittedData.hidden,
      config: {
        ...JSON.parse(submittedData.config),
        icons: iconSvgs
      },
      style: JSON.parse(submittedData.style),
      texts: updateTextVariables.filter(text => !!text.value)
    }

    try {
      await updateContent(content.id, data)
      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <TextField label={t('common.title')} {...methods.register('title')} sx={{ width: '100%' }} />
        </Box>
        <Box>
          <TextField label={t('common.text')} {...methods.register('text')} sx={{ width: '100%' }} />
        </Box>
        {linkInputs}
        <Box width="600xpx">
          <Button
            variant="outlined"
            sx={{ my: 4 }}
            onClick={() => appendLink({ key: '', value: '', svgIcon: '' })}
          >
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <Divider />
        <ConfigAndStyleEditor content={content} schema={linkBoxConfigSchema} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
