import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Stack, Box, Chip, Typography, Divider, TextField } from '@mui/material'
import { UpdateContentInput } from '@typings/graphql'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { ContentFile, uploadFile } from '../mutation-helper/file'
import { syncContentTexts } from '../mutation-helper/text'
import { updateContent } from '../mutation-helper/content'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { scratchCardConfigSchema } from '../config-schemas/default/scratchCard'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

const textKeys = ['exerciseTitle', 'exerciseDescription'] as const

type UpdateScratchCardContentFormInput = InteractionCardFormInputs & {
  backgroundImage: ContentFile | File | null,
  foregroundImage: ContentFile | File | null,
  texts: Record<typeof textKeys[number], string>,
  hidden: boolean,
  config: string,
  style: string
}

export const ScratchCardContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const defaultValues = useMemo(() => {
    return {
      backgroundImage: content.files.find((file) => file.key === 'backgroundImage') || null,
      foregroundImage: content.files.find((file) => file.key === 'foregroundImage') || null,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || '',
      hidden: content.hidden ?? false,
      texts: content.texts,
      interactionCardTexts: interactionCardTextKeys.map((key) => ({
        key,
        value: content.texts[key] || ''
      }))
    }
  }, [content])

  const methods = useForm<UpdateScratchCardContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  const addImage = (file: File, key: 'backgroundImage' | 'foregroundImage') => {
    methods.setValue(key, file, { shouldDirty: true })
  }

  const backgroundImage = useWatch({
    control: methods.control,
    name: 'backgroundImage'
  })

  const foregroundImage = useWatch({
    control: methods.control,
    name: 'foregroundImage'
  })

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = methods.handleSubmit(async (submittedData: UpdateScratchCardContentFormInput) => {
    setLoading(true)

    const translatableTexts = submittedData.interactionCardTexts.reduce((acc, curr) => {
      if (curr.value) {
        acc[curr.key] = curr.value
      }

      return acc
    }, {} as Record<string, string>)

    textKeys.forEach((key) => {
      if (submittedData.texts[key]) {
        translatableTexts[key] = submittedData.texts[key]
      }
    })

    const texts = await syncContentTexts(content, translatableTexts)

    if (submittedData.descriptionImage?.file && !(submittedData.descriptionImage.file as any).id) {
      try {
        await uploadFile({
          file: submittedData.descriptionImage.file,
          data: {
            key: 'description',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (submittedData.backgroundImage && !(submittedData.backgroundImage as any).id) {
      try {
        await uploadFile({
          file: submittedData.backgroundImage,
          data: {
            key: 'backgroundImage',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (submittedData.foregroundImage && !(submittedData.foregroundImage as any).id) {
      try {
        await uploadFile({
          file: submittedData.foregroundImage,
          data: {
            key: 'foregroundImage',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (methods.formState.isDirty) {
      const data: UpdateContentInput = {
        nuggetId,
        order: content.order,
        type: content.type,
        hidden: submittedData.hidden,
        config: JSON.parse(submittedData.config),
        style: JSON.parse(submittedData.style),
        texts
      }

      try {
        await updateContent(content.id, data)
        methods.reset()
      } catch (e) {
        setUploadError(e)
      }

      setLoading(false)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={onSubmit}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <InteractionCardEditor content={content} />
          <Divider sx={{ paddingBottom: '40px' }} />

          <Box paddingBottom="40px">
            <TextField sx={{ width: '500px' }} label={t('edit.content.exerciseTitle')} {...methods.register('texts.exerciseTitle')} />
          </Box>
          <Box paddingBottom="40px">
            <TextField sx={{ width: '500px' }} label={t('edit.content.exerciseDescription')} {...methods.register('texts.exerciseDescription')} />
          </Box>

          <Box paddingBottom="40px">
            <Chip label={t('edit.content.backgroundImage')} size="small" sx={{ mb: 1 }} />
            <FileDragDrop
              accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
              height="200px"
              preview
              onFileChanged={(file) => addImage(file, 'backgroundImage')}
            >
              {!backgroundImage
                ? <Typography>{t('edit.poi.dragImage')}</Typography>
                : <img
                    width="100%"
                    height="100%"
                    src={(backgroundImage as any).url ? (backgroundImage as ContentFile).url : URL.createObjectURL(backgroundImage as File)}
                    alt={t('edit.content.backgroundImage')}
                    style={{ objectFit: 'contain' }}
                />}
            </FileDragDrop>
          </Box>

          <Box>
            <Chip label={t('edit.content.foregroundImage')} size="small" sx={{ mb: 1 }} />
            <FileDragDrop
              accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
              height="200px"
              preview
              onFileChanged={(file) => addImage(file, 'foregroundImage')}
            >
              {!foregroundImage
                ? <Typography>{t('edit.poi.dragImage')}</Typography>
                : <img
                    width="100%"
                    height="100%"
                    src={(foregroundImage as any).url ? (foregroundImage as ContentFile).url : URL.createObjectURL(foregroundImage as File)}
                    alt={t('edit.content.foregroundImage')}
                    style={{ objectFit: 'contain' }}
                />}
            </FileDragDrop>
          </Box>

          <Box marginY="64px">
            <ConfigAndStyleEditor content={content} schema={scratchCardConfigSchema} />
          </Box>
          <VisibilityEditor blocked={blocksContent} />
        </Stack>

        <FormFooterBar
          disabled={!methods.formState.isDirty}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
