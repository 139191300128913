import { Box, Button, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentTypeEditors } from '@features/cms/components/content-type-editors'
import { CloneContentByIdMutation, ContentDataFragmentDoc, CreateContentMutation, useCloneContentByIdMutation, useCreateContentMutation, useTourModeQuery } from '@typings/graphql'
import { LoadingButton } from '@mui/lab'

type Props = {
  modeId: string
  nuggetId: string
  onClose: () => void
  onCreated: (id: string) => void
  show: boolean
}

const CUID_REGEXP = /^[cC][a-zA-Z0-9]{24}$/

export const CreateContentDialog: React.FC<Props> = ({ modeId, nuggetId, onClose, onCreated, show }) => {
  const { t } = useTranslation()
  const { data: modeData } = useTourModeQuery({ variables: { id: modeId as string } })
  const [selectedContentType, setSelectedContentType] = useState<string>('')
  const [id, setId] = useState<string>('')
  const [invalidIdError, setInvalidIdError] = useState<string>('')
  const [createContent, { loading }] = useCreateContentMutation()
  const [cloneContentById, { loading: cloning, reset }] = useCloneContentByIdMutation({
    onError: (e) => setInvalidIdError(t(`errors.${e.message}`, { defaultValue: e.message }))
  })

  const menuItems = useMemo(() => {
    if (!modeData) return null

    const types = ContentTypeEditors[modeData.tourMode.type as keyof typeof ContentTypeEditors] ?? ContentTypeEditors.default
    const keys = Object.keys(types)
    keys.sort()

    return keys.map((ct) => <MenuItem key={ct} value={ct}>{ct}</MenuItem>)
  }, [ContentTypeEditors, modeData])

  useEffect(() => {
    if (!show) {
      setInvalidIdError('')
      reset()
    }
  }, [show])

  const onCreate = async () => {
    if (!selectedContentType.length && !CUID_REGEXP.test(id)) {
      setInvalidIdError(t('errors.invalidId'))
      return
    }

    try {
      const { data } = selectedContentType.length
        ? await createContent({
          variables: {
            data: {
              nuggetId,
              type: selectedContentType!,
              texts: []
            }
          },
          update: (cache, { data: newContent }) => {
            if (!newContent) return

            cache.modify({
              id: cache.identify({
                __typename: 'ContentNugget',
                id: nuggetId
              }),
              fields: {
                content (existingRefs = []) {
                  const ref = cache.writeFragment({
                    data: newContent.createContent,
                    fragment: ContentDataFragmentDoc,
                    fragmentName: 'ContentData'
                  })

                  return [...existingRefs, ref]
                }
              }
            })
          }
        })
        : await cloneContentById({
          variables: {
            data: {
              contentId: id,
              targetNuggetId: nuggetId,
              tourModeId: modeId
            }
          },
          update: (cache, { data: newContent }) => {
            if (!newContent) return

            cache.modify({
              id: cache.identify({
                __typename: 'ContentNugget',
                id: nuggetId
              }),
              fields: {
                content (existingRefs = []) {
                  const ref = cache.writeFragment({
                    data: newContent.cloneContentById,
                    fragment: ContentDataFragmentDoc,
                    fragmentName: 'ContentData'
                  })

                  return [...existingRefs, ref]
                }
              }
            })
          }
        })

      if (!data) return

      const newContent = selectedContentType.length
        ? (data as CreateContentMutation).createContent
        : (data as CloneContentByIdMutation).cloneContentById

      onCreated(newContent.id)

      setId('')
      setSelectedContentType('')
    } catch (e) {
      console.log(e)
    }
  }

  return <Dialog open={show} onClose={onClose} sx={{ width: '100%', maxHeight: '90vh' }}>
    <DialogContent>
      <Box p={2} pb={0}>
        <Typography>{t('edit.poi.selectContentType')}</Typography>
        <Box mt={2} mb={4}>
          <InputLabel id="status-label">{t('edit.content.type')}</InputLabel>
          <Select
            sx={{ width: '100%' }}
            labelId="status-label"
            value={selectedContentType}
            label="Age"
            disabled={!!id}
            onChange={v => setSelectedContentType(v.target.value)}
          >
            {menuItems}
          </Select>
        </Box>
        <Typography my={2}>{t('edit.poi.pasteContentId')}</Typography>
        <TextField
          error={!!invalidIdError}
          fullWidth
          variant="outlined"
          label={t('edit.content.id')}
          value={id}
          disabled={!!selectedContentType}
          helperText={invalidIdError}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setId(event.target.value)}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button color='secondary' onClick={onClose}>
        {t('common.close')}
      </Button>
      <LoadingButton
        color="primary"
        onClick={onCreate}
        loading={loading || cloning}
        disabled={!selectedContentType && !id}
      >
        {t('edit.content.create')}
      </LoadingButton>
    </DialogActions>
  </Dialog>
}
