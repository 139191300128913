import FileDragDrop from '@features/cms/components/FileDragDrop'
import { Box, Chip, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UpdateContentInput } from '@typings/graphql'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ContentFile, isGraphQlFile, updateFile, uploadFile } from '../mutation-helper/file'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { syncContentTexts } from '../mutation-helper/text'
import { imageConfigSchema } from '../config-schemas/default/image'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateImageContentFormInput = {
  title: string,
  subtitle: string,
  image: ContentFile | File | undefined | null,
  blockedById: string | null,
  hidden: boolean,
  style: string,
  config: string
}

const textKeys = ['title', 'subtitle'] as const

export const ImageContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getDefaultValues = () => {
    return {
      title: content.texts.title ?? '',
      subtitle: content.texts.subtitle ?? '',
      image: content.files[0],
      blockedById: content.blockedById || null,
      hidden: content.hidden ?? false,
      style: JSON.stringify(content.style, null, '\t') || '',
      config: JSON.stringify(content.config, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateImageContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const addImage = (file: File | null) => {
    methods.setValue('image', file, { shouldDirty: true })
  }

  const onSubmit = async (submittedData: UpdateImageContentFormInput) => {
    setLoading(true)

    const translatableTexts = textKeys.reduce((acc, key) => {
      if (submittedData[key]) {
        acc[key] = submittedData[key]
      }

      return acc
    }, {} as Record<string, string>)

    const texts = await syncContentTexts(content, translatableTexts)

    if (submittedData.image && !isGraphQlFile(submittedData.image)) {
      try {
        const { copyright: newCopyright } = submittedData.image as any
        if (newCopyright) {
          delete newCopyright.__typename
        }

        await uploadFile({
          file: submittedData.image,
          data: {
            key: 'image',
            replace: true,
            model: 'Content',
            modelId: content.id,
            copyright: newCopyright
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    } else if (isGraphQlFile(submittedData.image)) {
      const { copyright: newCopyright } = submittedData.image as any
      if (newCopyright) {
        delete newCopyright.__typename
      }

      await updateFile({
        id: submittedData.image.id,
        data: {
          copyright: newCopyright
        }
      })
    }

    if (methods.formState.isDirty) {
      const data: UpdateContentInput = {
        nuggetId,
        order: content.order,
        type: content.type,
        blockedById: submittedData.blockedById || null,
        hidden: submittedData.hidden,
        style: JSON.parse(submittedData.style),
        config: JSON.parse(submittedData.config),
        texts
      }

      try {
        await updateContent(content.id, data)
        methods.reset()
      } catch (e) {
        setUploadError(e)
      }

      setLoading(false)
    }

    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <Chip label="Image" size="small" sx={{ mb: 1 }} />
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="200px"
            copyright
            preview
            initialFile={content?.files[0]}
            onFileChanged={(file) => addImage(file)}
          />
        </Box>
        <Stack py={4}>
          <Box>
            <Chip label={t('common.title')} size="small" sx={{ mb: 1 }} />
            <MarkdownEditor
              name="title"
              style={{ marginBottom: '40px' }}
            />
          </Box>
          <TextField
            sx={{ width: '500px' }}
            label={t('common.subtitle')}
            {...methods.register('subtitle')}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
        <ConfigAndStyleEditor content={content} schema={imageConfigSchema} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={() => setShowSnackbar(false)}
      />
    </form>
  </FormProvider>
}
