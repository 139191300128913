import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Button, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { UpdateContentInput, UpdateFileMutationVariables, UploadFileMutationVariables } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { EditableFile, InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { updateContent } from '../mutation-helper/content'
import {
  ContentFile, deleteContentFiles,
  replaceContentFiles, updateContentFiles, uploadContentFiles
} from '../mutation-helper/file'
import { deleteTexts } from '../mutation-helper/text'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { arConfigSchema } from '../config-schemas/default/ar'
import { VisibilityEditor } from '../components/VisibilityEditor'

import { ContentEditorComponentProps } from '.'

type UpdateArContentFormInput = InteractionCardFormInputs & {
  texts: {
    [key: string]: string
  },
  files: EditableFile[],
  hidden: boolean,
  blockedById: string | null,
  style: string,
  config: string
}

export const ArContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, blocksContent, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedFileIds, setDeletedFileIds] = useState<string[]>([])

  const textKeys = ['hint', 'accessibilityHint', 'accessibilityAnnouncement']

  const getDefaultValues = () => {
    const texts: Record<string, string> = {}

    textKeys.forEach((key) => {
      texts[key] = content.texts[key]
    })

    return {
      interactionCardTexts: interactionCardTextKeys.map((key) => ({
        key,
        value: content.texts[key] || ''
      })),
      descriptionImage: {
        key: 'description',
        file: content.files.find((file) => file.key === 'description') || null,
        replaceId: null
      },
      texts,
      files: [...content.files].filter((file) => file.key !== 'description').map((file) => ({
        file,
        replaceId: null,
        key: file.key || ''
      })),
      hidden: content.hidden ?? false,
      blockedById: content.blockedById || null,
      style: JSON.stringify(content.style, null, '\t') || '',
      config: JSON.stringify(content.config, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateArContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: 'files'
  })

  const replaceFile = (newFile: File, oldFile: ContentFile, index: number) => {
    methods.setValue(`files.${index}.file`, newFile, { shouldDirty: true })

    if (oldFile) methods.setValue(`files.${index}.replaceId`, oldFile.id)
  }

  const deleteFile = (index: number, fileId: string) => {
    remove(index)

    if (fileId) setDeletedFileIds((prev) => [...prev, fileId])
  }

  const addFileItem = () => {
    append({
      file: null,
      replaceId: null,
      key: ''
    })
  }

  const fileEditItems = useMemo(() => (
    fields.map((field, index) => {
      const { file, id } = field

      return (
        <Box
          key={id}
          height="150px"
          display="flex"
          alignItems="flex-start"
          padding="16px"
          border="1px solid #CFD7DD"
        >
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'], 'application/object': ['.obj'], 'text/plain': ['.mtl'] }}
            height="100%"
            width="30%"
            initialFile={file as ContentFile}
            preview
            onFileChanged={(newFile) => replaceFile(newFile, file as ContentFile, index)}
          />
          <Controller
            control={methods.control}
            name={`files.${index}.key`}
            rules={{ required: true }}
            render={({ field: { onChange }, fieldState: { error: valueError } }) =>
              <TextField
                value={methods.watch(`files.${index}.key`)}
                onChange={onChange}
                error={!!valueError}
                sx={{ marginLeft: '18px', width: '500px' }}
                label={t('common.key')}
                InputLabelProps={{ shrink: true }}
                helperText={!!valueError && t('common.required')}
              />
          }
          />
          <Box padding="10px" height="56px">
            <IconButton onClick={() => deleteFile(index, (file as any)?.id)}>
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
        </Box>
      )
    })
  ), [fields])

  const onSubmit = async (submittedData: UpdateArContentFormInput) => {
    setLoading(true)

    const allFiles = [...submittedData.files.map((file) => file), submittedData.descriptionImage]

    const newFilesUploadVariables = allFiles.reduce((acc, curr, index) => {
      if (curr.file && !(curr.file as any).id && !curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            order: index,
            model: 'Content',
            modelId: content.id,
            keepFileType: curr.key !== 'description'
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        setError(e)
      }
    }

    const replaceFileVariables = allFiles.reduce((acc, curr, index) => {
      if (curr.file && curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            replaceId: curr.replaceId,
            order: index,
            model: 'Content',
            modelId: content.id,
            keepFileType: curr.key !== 'description'
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (replaceFileVariables.length) {
      try {
        await replaceContentFiles(replaceFileVariables)
      } catch (e) {
        setError(e)
      }
    }

    const updateFileVariables = allFiles.reduce((acc, curr, index) => {
      if ((curr.file as any)?.id && !curr.replaceId && curr.key !== content.files[index].key) {
        acc.push({
          id: (curr.file as ContentFile).id,
          data: {
            key: curr.key,
            order: index
          }
        })
      }

      return acc
    }, [] as UpdateFileMutationVariables[])

    if (updateFileVariables) {
      try {
        await updateContentFiles(updateFileVariables)
      } catch (e) {
        setError(e)
      }
    }

    if (deletedFileIds.length) {
      const variables = deletedFileIds.map((id) => ({ id }))

      try {
        await deleteContentFiles(variables)
      } catch (e) {
        setError(e)
      }
    }

    const deletedTextsVariables = textKeys
      .filter((key) => !submittedData.texts[key] && content.texts[key])
      .map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

    if (deletedTextsVariables.length) {
      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const interactionCardUpdatedTexts = submittedData.interactionCardTexts
      .filter((text) => !!text.value)
      .map((text) => (convertToTextObject(text.key, text.value)))

    const updatedTexts = Object.keys(submittedData.texts)
      .filter((key) => submittedData.texts[key])
      .map((key) => convertToTextObject(key, submittedData.texts[key]))
      .concat(interactionCardUpdatedTexts)

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      hidden: submittedData.hidden,
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: updatedTexts
    }

    try {
      await updateContent(content.id, data)

      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content.files])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <InteractionCardEditor content={content} />
        <Divider />
        <Box>
          <TextField
            label="hint"
            sx={{ marginBottom: '24px', width: '100%' }}
            {...methods.register('texts.hint')}
          />
          <TextField
            label="accessibilityHint"
            sx={{ marginBottom: '24px', width: '100%' }}
            {...methods.register('texts.accessibilityHint')}
          />
          <TextField
            label="accessibilityAnnouncement"
            sx={{ marginBottom: '24px', width: '100%' }}
            {...methods.register('texts.accessibilityAnnouncement')}
          />
        </Box>
        {fileEditItems}
        <Box>
          <Button variant="outlined" onClick={addFileItem}>
            <Typography sx={{ marginRight: 1 }}>{t('edit.content.addFile')}</Typography>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <ConfigAndStyleEditor content={content} schema={arConfigSchema} />
        <ContentBlockEditor content={content} />
        <VisibilityEditor blocked={blocksContent} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
